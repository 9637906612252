import React, {useEffect, useState} from 'react';
import "../assets/scss/_cabinet.scss";
import "../assets/scss/_components.scss";
import "../assets/scss/_layout.scss"
import {Tabs} from 'antd';
import axios from "axios";
import EditProfile from "../components/modals/EditProfile";
import {useAccount, useNetwork} from 'wagmi';
import TestnetUserWhitelistModal from "../components/modals/TestnetUserWhitelistModal";
import {getUserAvatar, getUserProfile} from "../controllers/UserProfileController";
import NotificationsMessage from "../components/NotificationsMessage";
import UsersSettingsPage from "./UsersSettings/UsersSettingsPage";
import CompanyCard from "../components/CompanyCard";
import Skills from "../components/user/Skills";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import CreateSpaceIdModal from "../components/modals/CreateSpaceIdModal";
import Avatar from "../components/user/Avatar";
import Description from "../components/user/Description";
import {getReceivedNotifications} from "../controllers/NotificationController";
import {getDfoCompaniesByOwner, getDfoCompanyById} from "../controllers/ProjectsController";
import ProjectRemoveModal from "../components/modals/ProjectRemoveModal";
import MyTokens from "./UsersSettings/MyTokens";

export default function Cabinet() {
    const [isLoading, setIsLoading] = useState(true);
    const [spaceIdModal, setSpaceIdModal] = useState(false);
    const { TabPane } = Tabs;
    const { address } = useAccount();

    const [userData, setUserData] = useState({});
    const [userLogo, setUserLogo] = useState("https://media.istockphoto.com/id/1131164548/vector/avatar-5.jpg?s=612x612&w=0&k=20&c=CK49ShLJwDxE4kiroCR42kimTuuhvuo2FH5y_6aSgEo=");

    const [userRoles, setUserRoles] = useState();

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const [allowWhitelist, setAllowWhitelist] = useState(false);
    const [myOwnedCompanies, setMyOwnedCompanies] = useState([]);
    const [companiesIFollow, setCompaniesIFollow] = useState([]);

    const [editDataModal, setEditDataModal] = useState(false);
    const [addCurator, setAddCurator] = useState(false);
    const [removeProject, setRemoveProject] = useState(false);
    const [showWhitelistUserModal, setShowWhitelistUserModal] = useState(false);

    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        getDfos();
        getCompaniesIFollow();
        geActiveUserProfile();
        const addresses = process.env.REACT_APP_TESTNET_WHITELISTER_ADDRESS.split(',');
        for (const addressFromEnv of addresses){
            if (address === addressFromEnv){
                setAllowWhitelist(true);
            }
        }
    }, [])
    useEffect(() => {
        getNotifications()
    }, [])

    const { chain } = useNetwork()

    const getNotifications = async () => {
        try {
            const notificationsData = await getReceivedNotifications(address);
            const notificationsFromDb = notificationsData.map((notification) => (
                <NotificationsMessage key={notification.id} notification={notification} />
            ));
            setNotifications(notificationsFromDb);
        } catch (error) {
            console.error('Error in fetching notifications:', error);
        }
    };

    const geActiveUserProfile = async () => {
        await getUserProfile(address)
            .then(response => {
                setUserData ({
                        nickName: response.data.nickName,
                        walletId: address,
                        facebook: response.data.facebook,
                        discord: response.data.discord,
                        telegram: response.data.telegram,
                        twitter: response.data.twitter,
                        youtube: response.data.youtube,
                        linkedin: response.data.linkedin,
                        aboutme: response.data.aboutme,
                        email: response.data.email,
                        userRole: response.data.userRole,
                        refLink: response.data.refLink,
                        preferredSocialMedia: response.data.preferredSocialMedia,
                })
                setUserRoles( {
                    isFundraisingAdvisory: response.data.isFundraisingAdvisory,
                    isStrategicAdvisory: response.data.isStrategicAdvisory,
                    isLegalAdvisory: response.data.isLegalAdvisory,
                    isTechnicalAdvisory: response.data.isTechnicalAdvisory,
                    isFinancialAdvisory: response.data.isFinancialAdvisory,
                    isProductAdvisory: response.data.isProductAdvisory,
                    isMarketingAdvisory: response.data.isMarketingAdvisory,
                    isTokenomicsAdvisory: response.data.isTokenomicsAdvisory,
                    isGotoMarketStrategy: response.data.isGotoMarketStrategy,
                    isGrowthStrategy: response.data.isGrowthStrategy,
                    isListings: response.data.isListings,
                    isLiquidity: response.data.isLiquidity,
                    isInfrastructure: response.data.isInfrastructure,
                    isSmartContracts: response.data.isSmartContracts,
                    isNFT: response.data.isNFT,
                    isMetaverse: response.data.isMetaverse,
                    isGameFi: response.data.isGameFi,
                    isMarketingExecution: response.data.isMarketingExecution,
                    isPR: response.data.isPR,
                    isContentCreation: response.data.isContentCreation,
                    isTwitterKOL: response.data.isTwitterKOL,
                    isYoutubeKOL: response.data.isYoutubeKOL,
                    isInfluencer: response.data.isInfluencer,
                    isAmbassador: response.data.isAmbassador,
                    isCommunityBuilding: response.data.isCommunityBuilding,
                    isCommunityManagement: response.data.isCommunityManagement,
                    isDesign: response.data.isDesign,
                    isUIUX: response.data.isUIUX,
                    isBusinessModel: response.data.isBusinessModel,
                    isPitchDeck: response.data.isPitchDeck,
                    isWhitepaper: response.data.isWhitepaper,
                    isHrRecruiting: response.data.isHrRecruiting,
                    isDefi: response.data.isDefi,
                    isAi: response.data.isAi,
                    isTokenDesign: response.data.isTokenDesign
                })

                getCompaniesIFollow(response.data.followCompanies)
            })

        try {
            const data = await getUserAvatar(address);
            if (data.avatarLogo !== undefined) {
                setUserLogo(data.avatarLogo);
            }
        } catch (error) {
            console.error('Error fetching user profile logo:', error);
        } finally {
            setIsLoading(false);
        }
     }

    const getDfos = async() => {
        setMyOwnedCompanies(await getDfoCompanyData(address));
    };

    const getCompaniesIFollow = async (walletAddresses) => {
        const companiesList = [];
        try {
            for (const walletAddress of walletAddresses) {
                const dfoData = await getDfoCompanyById(walletAddress);
                if (dfoData !== null) {
                    companiesList.push(<CompanyCard key={dfoData.id} item={dfoData} />);
                }
            }
            setCompaniesIFollow(companiesList);
        } catch (error) {
            console.error('Error fetching companies I follow:', error);
        }
    };

    const getDfoCompanyData = async () => {
        try {
            const dfos = await getDfoCompaniesByOwner(address);
            return dfos
                .filter((dfo) => dfo !== null)
                .map((dfo) => <CompanyCard key={dfo.id} item={dfo}/>);
        } catch (error) {
            console.error('Error fetching my DFO companies:', error);
        }
    };

    const handleCloseEditModal = () => {
        setEditDataModal(false);
    }

    return (
        <>
            <div className="back home"></div>
            <div className="main-container">
                <div className='user-data'>
                    <div className='-container'>
                    {isLoading ?
                        <div className='-user-info-block'>
                            <Skeleton variant="rounded" animation="wave" classes='-image' height={200} width={200} />
                            <Stack spacing={1}>
                                <Skeleton variant="rectangular" animation="wave" height={25} width={200} />
                                <Skeleton variant="rectangular" animation="wave" height={15} width={300} />
                                <Skeleton variant="rectangular" animation="wave" height={15} width={300} />
                                <Skeleton variant="rectangular" animation="wave" height={40} width={300} />
                                <Skeleton variant="rectangular" animation="wave" height={70} width={250} />
                            </Stack>

                         </div>
                         :
                        <div className='-user-info-block'>
                            <div>
                                <Avatar name={userData.nickName} img={userLogo} socials={userData} disableName={true}/>
                            </div>
                            <div>
                                <div className='-nickName xl bold'>{userData.nickName}</div>
                                {userData?.walletId &&
                                <Description title="Wallet address" text={userData.walletId}/>
                                }
                                {userData?.aboutme  &&
                                   <Description title="Description" text={userData.aboutme}/>
                                }
                                <Skills roles={userRoles} />
                            </div>
                        </div>
                        }
                        <div className='-buttons'>
                            {chain && chain.network === "10243" &&
                                <a href={"https://faucet.arthera.net/"} target={"_blank"}>
                                    <button className='button-silver small-font'>Arthera faucet</button>
                                </a>
                            }
                            {allowWhitelist &&
                                <button className='button-silver small-font'  onClick={() => setShowWhitelistUserModal(true)}>Set curator</button>
                            }
                            {allowWhitelist &&
                                <button className='button-silver small-font'  onClick={() => setAddCurator(true)}>Add/Update curator</button>
                            }
                            {allowWhitelist &&
                            <button className='button-silver small-font'  onClick={() => setRemoveProject(true)}>Remove project</button>
                            }
                        </div>
                        <div className='-dfo-container'>
                            <Tabs>
                                {myOwnedCompanies.length > 0 &&
                                    <TabPane tab="My Projects" key="myProjects">
                                        <div className='-company-wrapper'>
                                            {myOwnedCompanies}
                                        </div>
                                    </TabPane>
                                }
                                <TabPane tab="My Tokens" key="tokens">
                                    <MyTokens />
                                </TabPane>
                                <TabPane tab="Followed Projects" key="followedProjects">
                                    <div className='-company-wrapper'>
                                        {companiesIFollow}
                                    </div>
                                    {companiesIFollow.length === 0 && <div>There are no projects you have followed by now</div>}
                                </TabPane>
                                <TabPane tab="Notifications" key="notifications">
                                    <div className='-links-wrapper'>
                                        {notifications}
                                    </div>
                                    {notifications.length === 0 && <div>There are no notifications</div>}
                                </TabPane>
                                <TabPane tab="Settings" key="editData">
                                    <UsersSettingsPage
                                        show={editDataModal}
                                        userData={userData}
                                        isAdmin={false}
                                        close={handleCloseEditModal}
                                        updateAvatar={geActiveUserProfile}
                                        userRoles={userRoles}
                                    />
                                </TabPane>

                            </Tabs>
                        </div>
                    </div>
                </div>
                <CreateSpaceIdModal show={spaceIdModal} close={() => setSpaceIdModal(false)} mainWalletAddress={userData.walletId} companyName={userData.nickName} isCompany={false}/>
                <EditProfile show={addCurator} userData={{}} userRoles={{}} isAdmin={true} close={() => setAddCurator(false)} />
                <TestnetUserWhitelistModal show={showWhitelistUserModal} close={() => setShowWhitelistUserModal(false)}/>
                <ProjectRemoveModal show={removeProject} close={() => setRemoveProject(false)}/>
            </div>
        </>
    );
}
