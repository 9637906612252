import {useEffect, useState} from 'react';
import '../assets/scss/_token-control.scss';
import { Button, Card, Input, Tabs, Typography } from 'antd';
import { ArrowRightOutlined, BankOutlined, FireOutlined, PauseCircleOutlined } from '@ant-design/icons';
import {
    getTotalSupply,
    pause,
    unpause,
    burn,
    mint,
    renounceOwnership,
    transferOwnership,
    getTokenTicker
} from "../ethers/ERC20Contract";
import {useLocation} from "react-router-dom";
import Web3 from "web3";

const { TabPane } = Tabs;
const { Text } = Typography;

export default function TokenManagement() {
    const location = useLocation();
    const saleUUID = new URLSearchParams(location.search).get("id");
    const [totalSupply, setTotalSupply] = useState('');
    const [ticker, setTicker] = useState('');

    const [newOwnerAddress, setNewOwnerAddress] = useState('');
    const [mintAmount, setMintAmount] = useState('');
    const [burnAmount, setBurnAmount] = useState('');

    const getTokenData = async() =>{
        setTicker(await getTokenTicker(saleUUID))
        setTotalSupply(Web3.utils.fromWei((await getTotalSupply(saleUUID)).toString(), "ether"))

    }

    useEffect(() => {
        getTokenData()
    }, []);

    const handleMint = async () => {
        await mint(saleUUID, mintAmount);
    };

    const handleBurn = async () => {
        await burn(saleUUID, burnAmount);
    };


    const handleRenounce = async () => {
        await renounceOwnership(saleUUID);
    };

    const handleTransfer = async () => {
       await transferOwnership(saleUUID, newOwnerAddress)
    };

    const handlePause = async () => {
        await pause(saleUUID);
    };

    return (
        <>
        <div className='back' />
    <div className="main-container">
        <div className="token-management">
                <div className="-title center">Manage your {ticker} tokens</div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4px' }}>
                    <div>
                        <div className="-text">Ticker</div>
                        <div className="-text xl">{ticker}</div>
                    </div>
                    <div>
                        <div className="-text">Total Supply</div>
                        <div className="-text xl">{totalSupply}</div>
                    </div>
                </div>
                <div className="-text">Owner Address</div>
                <Text style={{ display: 'block', background: '#f0f0f0', padding: '8px', borderRadius: '4px',}}>
                    {saleUUID}
                </Text>

                <Tabs centered defaultActiveKey="1" animated>

                    <TabPane tab="Mint" key="2">
                        <Input
                            placeholder="Amount to mint"
                            value={mintAmount}
                            onChange={(e) => setMintAmount(e.target.value)}
                            style={{ marginBottom: '16px' }}
                        />
                        <Button type="primary" onClick={handleMint} style={{ width: '100%' }}>
                            Mint Tokens
                        </Button>
                    </TabPane>
                    <TabPane tab="Burn" key="3">
                        <Input
                            placeholder="Amount to burn"
                            value={burnAmount}
                            onChange={(e) => setBurnAmount(e.target.value)}
                            style={{ marginBottom: '16px' }}
                        />
                        <Button type="primary" danger onClick={handleBurn} style={{ width: '100%' }}>
                            Burn Tokens <FireOutlined style={{ marginLeft: '8px' }} />
                        </Button>
                    </TabPane>
                    <TabPane tab="Ownership" key="1">
                        <Input
                            placeholder="New owner address"
                            value={newOwnerAddress}
                            onChange={(e) => setNewOwnerAddress(e.target.value)}
                            style={{ marginBottom: '16px' }}
                        />
                        <Button type="primary" onClick={handleTransfer} style={{ width: '100%' }}>
                            Transfer Ownership <ArrowRightOutlined style={{ marginLeft: '8px' }} />
                        </Button>
                        <Button onClick={handleRenounce} style={{ width: '100%', marginTop: '10px' }}>
                            Renounce Ownership
                        </Button>
                    </TabPane>
                    <TabPane tab="Pause" key="4">
                        <Button onClick={handlePause} style={{ width: '100%' }}>
                            <PauseCircleOutlined style={{ marginRight: '8px' }} /> Pause
                        </Button>
                    </TabPane>
                </Tabs>
            </div>
        </div>
        </>
    );
}
