import axios from "axios";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const getDfoCompanyById = async (apiUrl, id, chain) => {
    try {
        const response = await axios.get(apiUrl + '/api/getDfoCompanyById', {
            params: { mainWalletAddress: id, network: chain }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching DFO company data:", error);
        throw error;
    }
};

export const getDfoCompanies = async () => {
    try {
        const response = await axios.get(apiUrl + '/api/getDfoCompanies', {
            params: {
                companyName: '',
                pageNumber: 1,
                pageSize: 9,
            },
        });
        return response.data.transformedData.map((obj) => ({ ...obj, logo: "" }));
    } catch (error) {
        console.error("Error fetching DFO companies", error);
        throw error;
    }
};

export const getReferredCompanies = async (referralAddress) => {
    try {
        const response = await axios.get(apiUrl + '/api/getReferredCompanies', {
            params: {
                referredBy: referralAddress
            },
        });
        return response.data.map((obj) => ({ ...obj, logo: "" }));
    } catch (error) {
        console.error("Error fetching DFO companies", error);
        throw error;
    }
};

export const getDfoCompaniesV2 = async (query) => {
    const response = await axios.get(apiUrl + '/api/getDfoCompanies', query);
    return response.data;
};

export const getDfoCompanyLogo = async (companyName, network) => {
    try {
        const response = await axios.get(apiUrl + '/api/getDfoCompanyLogo', {
            params: { companyName, network },
        });
        return response.data[0].logo;
    } catch (error) {
        console.error("Error fetching DFO company logo", error);
        throw error;
    }
};

export const requestUpdateCompanyData = async (companyName, companyData, base64) => {
    try {
        const response = await axios.post(apiUrl + '/secured/api/updateCompany', {
            companyName,
            logo: base64,
            twitter: companyData.twitter,
            telegram: companyData.telegram,
            discord: companyData.discord,
            youtube: companyData.youtube,
            facebook: companyData.facebook,
            about: companyData.about,
            documentation: companyData.documentation,
            elevatorPitch: companyData.elevatorPitch,
            linkedin: companyData.linkedin,
            websiteUrl: companyData.websiteUrl
        });
        return response.data;
    } catch (error) {
        console.error("Error updating company data", error);
        throw error;
    }
};

export const getDFoCompaniesCountByOwner = async (address) => {
    return axios.get(apiUrl + '/api/getDFoCompaniesCountByOwner', {
        params: { owner: address }
    });
};

export const getDfoCompaniesByOwner = async (ownerAddress) => {
    try {
        const response = await axios.get(apiUrl + '/api/getDfoCompaniesByOwner', {
            params: { owner: ownerAddress },
        });
        return response.data; // Returns an array of DFO companies
    } catch (error) {
        console.error('Error fetching DFO companies by owner:', error);
        throw error;
    }
};
