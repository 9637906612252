import React, {useEffect, useState, useRef} from 'react';
import "../assets/scss/_registration.scss"
import "../assets/scss/_components.scss"
import {useNavigate} from "react-router-dom";
import {useValidForm} from "../hooks/useValidForm";
import FileImg from "../assets/icons/FileIcon.svg"
import Rocket from "../assets/images/registration/Rocket.png"
import {useValidFile} from "../hooks/useValidFile";
import LoadingModal from "../components/modals/LoadingModal";
import {useAccount, useNetwork} from 'wagmi'
import {initializeDfo} from "../controllers/RegistrationController"
import {getUserProfileByRefLink} from "../controllers/UserProfileController";
import {toast} from "react-toastify";
import {useAuthenticate} from "../hooks/useAuthenticate";
import Ok from "../assets/icons/home/ok.svg";
import {Input, Button} from "antd";

export default function Registration() {

    const { chain } = useNetwork()
    const [dfoName, setDfoName] = useState("");
    const dfoTicker = useValidForm('', 2);
    const [dfoType, setDfoType] = useState('public');
    const fileDrop = useValidFile(null);
    const navigate = useNavigate();
    const { address } = useAccount();

    const { authenticate } = useAuthenticate();

    const[errorText, setErrorText] = useState("");

    const [Loading, setLoading] = useState(false);

    const[referralAddress, setReferralAddress] = useState("0x0000000000000000000000000000000000000000");

    function validateRegistration() {
        fileDrop.trigger();
        dfoName === "" && setErrorText("Must not be empty");
        if (!address){
            toast.error("Wallet not detected. Please connect your wallet to proceed.")
        }
        if (errorText === "" && fileDrop.isValid && address) {
            init(dfoName, address, referralAddress)
        }
    }

    useEffect(() => {
        async function getReferralAddress() {
            const referral = await getUserProfileByRefLink(localStorage.getItem("referral").replace(/"/g, ''));
            setReferralAddress(referral.data)
        }
        getReferralAddress();
    },[])

    async function init(_companyName, owner, referralAddress) {
        try {
            const response = await initializeDfo(_companyName, owner, dfoTicker.value, dfoType, fileDrop.value, chain.network, referralAddress);

            const statusCode = response.status;

            setLoading(true);

            setTimeout(() => {
                setLoading(false);
                if (statusCode === 200) {
                    navigate('/feedback?id=' + _companyName);
                }
            }, 1500);
        } catch (error) {
            console.error('Error during initialization:', error);
            if (error.response && error.response.status === 403) {
                toast.error("Project with title " + _companyName + " already exists")
            } else if(error.response && error.response.status === 401) {
                toast.error('Wallet not authorized, please sign the message')
                await authenticate(address)
            } else {
                toast.error('Error during initialization:' + error)
            }
        }
    }

    const handleSetDfoName = e => {
        setDfoName(e.target.value);
        setErrorText("");
    }

    return (
        <>
            <div className="registration-image">
                <div className="-title">List your project for free today</div>
            </div>
            <div className='registration-wrapper'>
                <img src={Rocket} className="-rocket-image" alt=""/>
                <div className="-title">Submit Your Project!</div>
                <div>
                    <div className="-text">
                        Title
                    </div>
                    <Input
                        placeholder='Project name'
                        value={dfoName}
                        onChange={e => handleSetDfoName(e)}
                    />
                    <div className='-valid-msg'>{errorText}</div>
                </div>
                <div>
                    <label for='choose-file' className='-dropdown'>
                            {!fileDrop.isUploaded ?
                                <>
                                    <img className='-img' src={FileImg} alt=""/>
                                    <div className='-text'>Click or drag file to this area to upload</div>
                                    <div className='-text s'>Support for a single or bulk upload.</div>
                                </>
                            :
                              <img src={fileDrop.file} className='-img active' alt=""/>
                            }
                        <input type="file" className='-input' id='choose-file' accept=".jpg, .jpeg, .png, .pdf, .svg" onChange={e => fileDrop.onChange(e)} />
                    </label>
                    {!fileDrop.isValid && <div className='-valid-msg'>{fileDrop.errorMessage}</div>}
                </div>
                <div>
                    <Button type="primary" onClick={()  => validateRegistration()}>Submit</Button>
                    {/*<Button >Reset</Button>*/}
                </div>
                <LoadingModal  show={Loading} text='Registration in process...' />
            </div>
        </>
    );
}
