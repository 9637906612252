import React, {useEffect, useState} from 'react';
import "../assets/scss/_dfo-page.scss"
import "../assets/scss/_components.scss"
import {NavLink, useLocation} from "react-router-dom";
import {Button, Card, Switch, Tag, Upload} from 'antd';

import Discord from "../assets/icons/curators/discord.svg";
import Facebook from "../assets/icons/curators/facebook.svg";
import Youtube from "../assets/icons/curators/youtube.svg";
import Twitter from "../assets/icons/curators/twitter.svg";
import Telegram from "../assets/icons/curators/telegram.svg";
import Linkedin from "../assets/icons/curators/linkedin.svg";
import CreateSpaceIdModal from "../components/modals/CreateSpaceIdModal";
import Ok from "../assets/icons/home/ok.svg";
import UpdatePackage from "../components/modals/UpdatePackage";
import ProjectSettings from "../components/project/ProjectSettings";
import SaleTable from "../components/token/table/SaleTable";
import TokenTable from "../components/token/table/TokenTable";
import VestingTable from "../components/token/table/VestingTable";
import {addFollowCompany, getUserProfile} from "../controllers/UserProfileController";
import {getDfoCompanyById} from "../controllers/ProjectsController";
import { useAccount } from '../ethers/utils/useAccount';
import {useNetwork} from "../ethers/utils/useNetwork";
const { ethers } = require('ethers');

export default function DfoPage() {

    const chain = useNetwork();
    const address = useAccount();

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const [imgUrl, setImgUrl] = useState('https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg');

    const location = useLocation();
    const params = new URLSearchParams(location.search)

    const [mainWalletAddress, setMainWalletAddress] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [creationDate, setCreationDate] = useState();
    const [about, setAbout] = useState('');
    const [documentation, setDocumentation] = useState('');
    const [elevatorPitch, setElevatorPitch] = useState('');
    const [twitter, setTwitter] = useState('');
    const [discord, setDiscord] = useState('');
    const [youtube, setYoutube] = useState('');
    const [facebook, setFacebook] = useState('');
    const [telegram, setTelegram] = useState('');
    const [linkedin, setLinkedIn] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [adminAddress, setAdminAddress] = useState('');

    const [tab, setTab] = useState("token");
    const [spaceIdModal, setSpaceIdModal] = useState(false);
    const [updatePackageModal, setUpdatePackageModal] = useState(false);

    const[isAdmin, setIsAdmin] = useState(false);
    const[projectStatus, setProjectStatus] = useState(false);
    const[listingTier, setListingTier] = useState("");

    const[showFollowButton, setShowFollowButton] = useState(true);
    const[disableFollowButton, setDisableFollowButton] = useState(false);

    const[isFollowAvialable, setIsFollowAvialable] = useState(false);

    const[packageName, setPackageName] = useState("")

    useEffect(() => {
        getDfoCompanyData();
        if (address){
            getAccountData();
        }
    },[mainWalletAddress, showFollowButton, address])

    const getAccountData = async () => {
        try {
            const userProfile = await getUserProfile(address);

            if (userProfile?.followCompanies.some(v => v === companyName)) {
                setShowFollowButton(false);
            }
            if (userProfile) {
                setIsFollowAvialable(true);
            }
        } catch (error) {
            console.error("Failed to get account data:", error);
        }
    };

    const followCompany = async () => {
        try {
            setDisableFollowButton(true);
            await addFollowCompany(apiUrl, companyName, address);
            setShowFollowButton(false);
        } catch (error) {
            // Handle the error (optional)
            console.error("Failed to follow company:", error);
        } finally {
            setDisableFollowButton(false);
        }
    };

    const removeFollowCompany = async () => {
        try {
            setDisableFollowButton(true);
            await removeFollowCompany(apiUrl, companyName, address);
            setShowFollowButton(true);
        } catch (error) {
            // Handle the error (optional)
            console.error("Failed to unfollow company:", error);
        } finally {
            setDisableFollowButton(false);
        }
    };

    const getDfoCompanyData = async () => {
        try {
            const data = await getDfoCompanyById(apiUrl, params.get("id"), params.get("chain"));

            if (data === null) {
                window.location.reload();
                return;
            }

            setImgUrl(data.logo);
            setAbout(data.about);
            setDocumentation(data.documentation);
            setElevatorPitch(data.elevatorPitch);
            setTwitter(data.twitter);
            setDiscord(data.discord);
            setYoutube(data.youtube);
            setFacebook(data.facebook);
            setTelegram(data.telegram);
            setLinkedIn(data.linkedin);
            setWebsiteUrl(data.websiteUrl);
            setCompanyName(data.companyName);
            setAdminAddress(ethers.getAddress(data.owner))
            setIsAdmin(ethers.getAddress(data.owner) === ethers.getAddress(address));
            setProjectStatus(data.projectStatus);
            setListingTier(data.listingTier);
            setMainWalletAddress(data.mainWalletAddress);
            setCreationDate(data.initializedTimestamp.substring(0, data.initializedTimestamp.indexOf('T')));

        } catch (error) {
            // Handle the error (optional)
            console.error("Failed to fetch DFO company data:", error);
        }
    };

    const[tokenAmountForApprove, setTokenAmountForApprove] = useState (750);
    const handleClosePackageModal = () => {
        setPackageName("")
        setTokenAmountForApprove(750.1);
        setUpdatePackageModal(false)
        getDfoCompanyData()
    }

    const handleBuyExplorerPackage = () => {
        setPackageName("Explorer")
        setTokenAmountForApprove(750.1);
    }

    const handleBuyAcceleratorPackage = () => {
        setPackageName("Accelerator")
        setTokenAmountForApprove(2000.1)
    }

    useEffect(() => {
        if (packageName !== ""){
            setUpdatePackageModal(true)
        }
    }, [packageName, chain])

    // TODO : read project packages from blockchain
    // useContractRead({
    //     address: process.env.REACT_APP_FORCEFI_PACKAGE_ADDRESS,
    //     abi: ForcefiPackage,
    //     functionName: 'viewProjectPackages',
    //     args: [params.get("id")],
    //     chainId: process.env.REACT_APP_ENV === "PROD" ? 1 : 5,
    //     onSuccess(data) {
    //         console.log(data)
    //         if(data[1] && listingTier !== "Accelerator"){
    //             updateListingTierInDb("Accelerator", params.get("id"))
    //                 .then(() => setListingTier("Accelerator"));
    //         } else if (data[0] && listingTier === "Starter"){
    //             updateListingTierInDb("Explorer", params.get("id"))
    //                 .then(() => setListingTier("Explorer"));
    //         }
    //     }
    // })

        return (
            <>
                <UpdatePackage amount={tokenAmountForApprove} packageName={packageName} companyName={companyName} show={updatePackageModal} close={() => handleClosePackageModal()} />
                <CreateSpaceIdModal show={spaceIdModal} close={() => setSpaceIdModal(false)} mainWalletAddress={mainWalletAddress} companyName={companyName}/>
                <div className='back' />
                <div className="main-container">
                    <div className='dfo-data first'>
                        <div className='-container'>
                            {isFollowAvialable &&
                                <div  className='-switch'>
                                    Follow
                                    <Switch checked={!showFollowButton} loading={disableFollowButton} onChange={showFollowButton ? ()=>followCompany() : () => removeFollowCompany()}/>
                                </div>
                            }
                            <div className='-main-info'>
                                <div className='-left'>
                                    <img className='-logo' alt=''
                                         src={imgUrl}/>

                                </div>
                                <div className='-dfo-data'>
                                    <div className='-text xl'>{companyName}</div>
                                    <div className="-socials">
                                        {linkedin !== undefined && linkedin.length !== 0 &&
                                            <a href={linkedin} target='_blank'>
                                                <img className='-item' alt='' src={Linkedin}/>
                                            </a>
                                        }
                                        {twitter !== undefined && twitter.length !== 0 &&
                                            <a href={twitter} target='_blank'>
                                                <img className='-item' alt='' src={Twitter}/>
                                            </a>
                                        }
                                        {telegram !== undefined && telegram.length !== 0 &&
                                            <a href={telegram} target='_blank'>
                                                <img className='-item' alt='' src={Telegram}/>
                                            </a>
                                        }
                                        {discord !== undefined && discord.length !== 0 &&
                                            <a href={discord} target='_blank'>
                                                <img className='-item' alt='' src={Discord}/>
                                            </a>
                                        }
                                        {youtube !== undefined && youtube.length !== 0 &&
                                            <a href={youtube} target='_blank'>
                                                <img className='-item' alt='' src={Youtube}/>
                                            </a>
                                        }
                                        {facebook !== undefined && facebook.length !== 0 &&
                                            <a href={facebook} target='_blank'>
                                                <img className='-item' alt='' src={Facebook}/>
                                            </a>
                                        }

                                    </div>
                                    <div className="-tags">
                                        {listingTier && <Tag color="blue" >{listingTier}</Tag>}
                                        { projectStatus && <Tag color="geekblue" >{projectStatus}</Tag>}
                                    </div>
                                    <div className="-tags">
                                        {websiteUrl !== "" && websiteUrl !== undefined &&

                                            <a href={websiteUrl} target='_blank'>
                                                <Tag
                                                >Website
                                                </Tag>
                                            </a>
                                        }
                                        {documentation !== "" && documentation !== undefined &&
                                            <a href={documentation} target='_blank'>
                                                <Tag
                                                >WhitePaper
                                                </Tag>
                                            </a>
                                        }
                                    </div>
                                    {about}

                                    {isAdmin &&
                                        <>
                                    <div className='-buttons'>
                                        <Button type={tab === "token" ? "primary" : "default"} onClick={() => setTab("token")}>Blockchain data</Button>
                                        <Button type={tab === "editProject" ? "primary" : "default"} onClick={() => setTab("editProject")}>Edit project data</Button>
                                        <a href="https://calendly.com/mskoblovs/meetings?month=2023-06" target='_blank'>
                                            <Button>Book a free call with Forcefi</Button>
                                        </a>
                                    </div>
                                </>
                                }
                                </div>

                            </div>
                            {(tab === "editProject") &&
                                <ProjectSettings
                                    companyName={companyName}
                                    imgUrl={imgUrl}
                                    about={about}
                                    documentation={documentation}
                                    twitter={twitter}
                                    discord={discord}
                                    youtube={youtube}
                                    facebook={facebook}
                                    telegram={telegram}
                                    linkedIn={linkedin}
                                    websiteUrl={websiteUrl}
                                    elevatorPitch={elevatorPitch}
                                />
                            }
                            { isAdmin && tab === "token" && <>
                                <Card size="default" title="Launch a fundraise" extra={ isAdmin ?
                                    <NavLink to="/fundraising" state={{ projectName: companyName }}>
                                        <Button type="primary">New Fundraise</Button>
                                    </NavLink>
                                    : <div></div>
                                }>
                                    <SaleTable projectName={companyName} />
                                </Card>

                                <Card size="default" title="Create token" extra={ isAdmin ?
                                    <NavLink to="/create-token" state={{ projectName: companyName }}>
                                        <Button type="primary">Create token</Button>
                                    </NavLink>
                                    : <div></div>
                                }>
                                    <TokenTable projectName={companyName}/>
                                </Card>

                                <Card size="default" title="Vesting" extra={ isAdmin ?
                                    <NavLink to="/vesting" state={{ projectName: companyName }}>
                                        <Button type="primary">Create vesting</Button>
                                    </NavLink> : <div></div>}>
                                    <VestingTable projectName={companyName} isAdmin={isAdmin}/>
                                </Card>
                                { isAdmin &&
                                <Card size="default" title="Project Setting">
                                    <div>Profile image</div>

                                    <Upload>
                                        <Button>Click to Upload</Button>
                                    </Upload>
                                    <div>Background image</div>
                                    <Upload>
                                        <Button>Click to Upload</Button>
                                    </Upload>
                                </Card>
                                }
                            </>

                            }
                            { !isAdmin && <>
                                <SaleTable projectName={companyName} adminAddress={adminAddress}/>
                                <VestingTable projectName={companyName} isAdmin={isAdmin}/>
                            </>}


                        </div>
                        {listingTier !== "Accelerator" && isAdmin &&
                        <div className="-container packages">
                            {listingTier === "Starter" &&
                            <div className="packages-card-dfo">
                                <div className='package'>
                                    <div className="-title explorer">Explorer</div>
                                    <div className="-advantages small-offset">
                                        <div className="-text-icon"><img src={Ok} alt=""/>All "Starter" benefits</div>
                                        <div className="-text-icon"><img src={Ok} alt=""/>Access to curators</div>
                                        <div className="-text-icon"><img src={Ok} alt=""/>Warm introductions with relevant
                                            curators
                                        </div>
                                        <div className="-text-icon"><img src={Ok} alt=""/>Co-marketing with Forcefi</div>
                                    </div>
                                    <button className='-button explorer' onClick={() => handleBuyExplorerPackage()}>Upgrade</button>
                                </div>
                            </div>
                            }
                            <div className="packages-card-dfo">
                                <div className='package'>
                                    <div className="-title accelerator">Accelerator</div>
                                    <div className="-advantages small-offset">
                                        <div className="-text-icon"><img src={Ok} alt=""/> All “Explorer” packages benefits
                                        </div>
                                        <div className="-text-icon"><img src={Ok} alt=""/> Warm introductions with Forcefi
                                            key partners
                                        </div>
                                        <div className="-text-icon"><img src={Ok} alt=""/> Free token sale launch on Forcefi
                                        </div>
                                        <div className="-text-icon"><img src={Ok} alt=""/> Featured Forcefi listing for 3
                                            months
                                        </div>
                                    </div>

                                    <button className='-button accelerator'
                                            onClick={() => handleBuyAcceleratorPackage()}>Upgrade
                                    </button>
                                </div>
                            </div>
                            We accept payments only in Tether USDT (ERC-20) for now. The fee will be charged from your wallet with which you connected and listed the project, so be sure you have enough funds on your wallet to complete the transaction and cover network fees.
                            We will add more payment options soon!
                        </div>
                        }
                    </div>
                </div>
            </>
        );
    }
