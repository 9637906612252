import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useValidFile} from "../../hooks/useValidFile";
import '../../assets/scss/_cabinet-settings.scss'
import axios from "axios";
import * as ethers from "ethers";
import convertToBase64 from "../../utils/FileConverter";
import {toast} from "react-toastify";
import Aim from "../../assets/icons/userSettings/aim.svg"
import Avatar from "../../assets/icons/userSettings/avatar.svg"
import Marker from "../../assets/icons/userSettings/marker.svg"

import {generateRefLink, getUserProfile, saveCurator, saveUserProfile} from "../../controllers/UserProfileController";
import rolesLabels from "../../json/rolesLabels";
import {Input, Button, Tag, Radio} from 'antd';
import {useAccount} from "wagmi";
import {useAuthenticate} from "../../hooks/useAuthenticate";

export default function UsersSettingsPage(props) {
    const fileDrop = useValidFile(null);
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const { TextArea } = Input;
    const { address } = useAccount();
    const { authenticate } = useAuthenticate();

    useEffect(() => {
        setUserData({
            nickName: props.userData.nickName,
            facebook: props.userData.facebook,
            discord: props.userData.discord,
            telegram: props.userData.telegram,
            twitter: props.userData.twitter,
            youtube: props.userData.youtube,
            linkedin: props.userData.linkedin,
            aboutme: props.userData.aboutme,
            email: props.userData.email
        });
        setRoles(props.userRoles);
        setReferralLink(props.userData.refLink);
        setUserRole(props.userData.userRole);
        setPreferredSocialMedia(props.userData.preferredSocialMedia);
    }, [props.userData])

    const [userData, setUserData] = useState({
        walletAddress: props.userData.walletId,
        nickName: "",
        facebook: "",
        discord: "",
        telegram: props.userData.telegram,
        twitter: props.userData.twitter,
        youtube: props.userData.youtube,
        linkedin: props.userData.linkedin,
        aboutme: props.userData.aboutme,
    })

    const rolesKeys = Object.keys(rolesLabels);
    const [roles, setRoles] = useState(rolesKeys.reduce((json, key) => {
            json[key] = false;
            return json;
        }, {})
    )
    const [userRole, setUserRole] = useState("user");
    const [preferredSocialMedia, setPreferredSocialMedia] = useState(props.userData.preferredSocialMedia);
    const [referralLink, setReferralLink] = useState("");
    console.log(userRole);
    const handleChangeUserRole = (event) => setUserRole(event.target.value);
    const handleRolesChange = (role) => {
        const updatedRoles = { ...roles, [role]: !roles[role] };
        setRoles(updatedRoles);
    };


    const [walletAddress, setWalletAddress] = useState("")
    const [newWalletAddress, setNewWalletAddress] = useState("")

    const shouldAuthenticate = () => {
        const expirationDate = Number(localStorage.getItem('expirationDate'));
        return expirationDate - new Date() < 0;
    };

    const submit = async(e) => {
        e.preventDefault();

        if (shouldAuthenticate()){
            toast.error("Unauthorized. Please sign in, and repeat the action.")
            authenticate(address);
        } else {
            const refLink = referralLink;
            let base64;
            if (fileDrop.value === null) {
                base64 = props.userData.avatarLogo
            } else {
                base64 = await convertToBase64(fileDrop.value);
            }

            if (props.isAdmin){
                await saveCurator(newWalletAddress, walletAddress, userData, roles, preferredSocialMedia, base64, userRole, refLink)
                    .then(() => {
                        toast.success("Curator saved");
                        props.close();
                    });
            } else {
                await saveUserProfile(address, userData, roles, preferredSocialMedia, base64, refLink)
                    .then(() => {
                        toast.success("Profile updated")
                        props.updateAvatar();
                        props.close();
                    })
                    .catch(() => {
                        toast.error("Unauthorized")
                        props.close();
                    });
            }
        }
    }


    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        setUserData((prev) => {
            return {
                ...prev,   // Spread Operator
                [name]: value
            }
        })
    }

    const fixInputLink = event => {
        let value = event.target.value;
        value.replace(/[:\/]/g, '');
        if (!value.match(/^(http|https|htt|htp):\/\//)) value = 'https://' + value;
        value = value.replace(/^((?!https:\/\/)[^:]+)?:\/\//, 'https://');
        let name = event.target.name;
        setUserData((prev) => {
            return {
                ...prev,   // Spread Operator
                [name]: value
            }
        })
    }

    const handleChangeNewWalletAddress = (event) => {
        setNewWalletAddress(event.target.value)
    }

    const handleChangeWalletAddress = async (event) => {
        setWalletAddress(event.target.value);
        await getUserProfile(event.target.value)
            .then(response => {
                console.log('TOKEN DESIGN ' + response.data.isTokenDesign)
                if (response.status === 204){
                    const emptyProperties = ["nickName", "facebook", "discord", "telegram", "twitter", "youtube", "linkedin", "aboutme", "email"];
                    const emptyUserData = emptyProperties.reduce((acc, property) => {
                        acc[property] = "";
                        return acc;
                    }, {});
                    setUserData(emptyUserData);
                    const roleKeys = Object.keys(rolesLabels);

                    const emptyUserRoles = roleKeys.reduce((acc, property) => {
                        acc[property] = false;
                        return acc;
                    }, {});
                    setRoles(emptyUserRoles);
                } else{
                    setUserData ({
                        nickName: response.data.nickName,
                        walletAddress: response.data.walletAddress,
                        facebook: response.data.facebook,
                        discord: response.data.discord,
                        telegram: response.data.telegram,
                        twitter: response.data.twitter,
                        youtube: response.data.youtube,
                        linkedin: response.data.linkedin,
                        aboutme: response.data.aboutme,
                        userRole: response.data.userRole,
                        email: response.data.email,
                        preferredSocialMedia: response.data.preferredSocialMedia,
                    })
                    setRoles( {
                        isFundraisingAdvisory: response.data.isFundraisingAdvisory,
                        isStrategicAdvisory: response.data.isStrategicAdvisory,
                        isLegalAdvisory: response.data.isLegalAdvisory,
                        isTechnicalAdvisory: response.data.isTechnicalAdvisory,
                        isFinancialAdvisory: response.data.isFinancialAdvisory,
                        isProductAdvisory: response.data.isProductAdvisory,
                        isMarketingAdvisory: response.data.isMarketingAdvisory,
                        isTokenomicsAdvisory: response.data.isTokenomicsAdvisory,
                        isGotoMarketStrategy: response.data.isGotoMarketStrategy,
                        isGrowthStrategy: response.data.isGrowthStrategy,
                        isListings: response.data.isListings,
                        isLiquidity: response.data.isLiquidity,
                        isInfrastructure: response.data.isInfrastructure,
                        isSmartContracts: response.data.isSmartContracts,
                        isNFT: response.data.isNFT,
                        isMetaverse: response.data.isMetaverse,
                        isGameFi: response.data.isGameFi,
                        isMarketingExecution: response.data.isMarketingExecution,
                        isPR: response.data.isPR,
                        isContentCreation: response.data.isContentCreation,
                        isTwitterKOL: response.data.isTwitterKOL,
                        isYoutubeKOL: response.data.isYoutubeKOL,
                        isInfluencer: response.data.isInfluencer,
                        isAmbassador: response.data.isAmbassador,
                        isCommunityBuilding: response.data.isCommunityBuilding,
                        isCommunityManagement: response.data.isCommunityManagement,
                        isDesign: response.data.isDesign,
                        isUIUX: response.data.isUIUX,
                        isBusinessModel: response.data.isBusinessModel,
                        isPitchDeck: response.data.isPitchDeck,
                        isWhitepaper: response.data.isWhitepaper,
                        isHrRecruiting: response.data.isHrRecruiting,
                        isDefi: response.data.isDefi,
                        isAi: response.data.isAi,
                        isTokenDesign: response.data.isTokenDesign
                    })
                }
            })
    }

    return (
        <form onSubmit={submit}>
            <div className='settings-page'>
                {props.isAdmin &&
                    <>
                        <div>
                            <div className='-text'>Wallet address</div>
                            <input
                                type="text"
                                className='-input'
                                name='walletAddress'
                                value={userData.walletAddress}
                                onChange={handleChangeWalletAddress}
                            />
                        </div>
                        <div>
                            <div className='-text'>New Wallet address</div>
                            <input
                                type="text"
                                className='-input'
                                name='newWalletAddress'
                                value={newWalletAddress}
                                onChange={handleChangeNewWalletAddress}
                            />
                        </div>
                    </>
                }
                <div className='-grid'>
                    <div>
                        <div className='-section'>
                            <img src={Marker} className='-img' alt=""/>
                            <div className='-text'>Avatar</div>
                        </div>
                    </div>
                    <div className='-input-grid'>
                        <div className='-text'></div>
                        <label htmlFor='choose-file' className='-dropdown'>
                            {!fileDrop.isUploaded ?
                                <>
                                    <div className='-text bold'>Drop your profile logo here or <span
                                        className='-accent'>browse</span></div>
                                    <div className='-text'>.PNG on transparent background<br/></div>
                                </>
                                :
                                <img src={fileDrop.file} className='-img' alt=""/>
                            }
                            <input type="file" className='-input' id='choose-file'
                                   accept=".jpg, .jpeg, .png, .pdf, .svg" onChange={e => fileDrop.onChange(e)}/>
                        </label>
                    </div>
                </div>
                <div className='-grid'>
                    <div>
                        <div className='-section'>
                            <img src={Avatar} className='-img' alt=""/>
                            <div className='-text'>Personal Information</div>
                        </div>
                    </div>
                    <div className='-input-grid'>
                        <div className='-text'>Username</div>
                        <Input
                            type="text"
                            name='nickName'
                            value={userData.nickName}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='-grid'>
                    <div>
                        <div className='-section'>
                            <img src={Marker} className='-img' alt=""/>
                            <div className='-text'>Nickname</div>
                        </div>
                    </div>
                    <div className="-input-wrapper">
                        <div className='-input-grid'>
                            <div className='-text'>Email</div>
                            <Input
                                type="text"
                                name='email'
                                value={userData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="-input-grid">
                            <div className='-text xs'>About me</div>
                            <TextArea
                                type="text"
                                name='aboutme'
                                maxLength="250"
                                value={userData.aboutme}
                                onChange={handleChange}
                            />
                        </div>

                    </div>
                </div>
                    <div className="-grid">
                        <div>
                            <div className='-section'>
                                <img src={Aim} className='-img' alt=""/>
                                <div className='-text'>Social Media Accounts</div>
                            </div>
                        </div>
                        <div className="-input-wrapper">
                            <div className='-input-grid'>
                                <div className='-text'>Telegram</div>
                                <Input
                                    type="text"
                                    name='telegram'
                                    value={userData.telegram}
                                    onChange={handleChange}
                                    onBlur={fixInputLink}
                                />
                            </div>
                            <div className='-input-grid'>
                                <div className='-text social-media'>Twitter</div>
                                <Input
                                    type="text"
                                    name='twitter'
                                    value={userData.twitter}
                                    onChange={handleChange}
                                    onBlur={fixInputLink}
                                />
                            </div>
                            <div className='-input-grid'>
                                <div className='-text social-media'>Discord</div>
                                <Input
                                    type="text"
                                    name='discord'
                                    value={userData.discord}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='-input-grid'>
                                <div className='-text'>Youtube</div>
                                <Input
                                    type="text"
                                    name='youtube'
                                    value={userData.youtube}
                                    onChange={handleChange}
                                    onBlur={fixInputLink}
                                />
                            </div>
                            <div className='-input-grid'>
                                <div className='-text'>Facebook</div>
                                <Input
                                    type="text"
                                    name='facebook'
                                    value={userData.facebook}
                                    onChange={handleChange}
                                    onBlur={fixInputLink}
                                />
                            </div>
                            <div className='-input-grid'>
                                <div className='-text'>Linkedin</div>
                                <Input
                                    type="text"
                                    name='linkedin'
                                    value={userData.linkedin}
                                    onChange={handleChange}
                                    onBlur={fixInputLink}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="-grid disable-border">
                        <div></div>
                        <div className="-left">
                            <Radio.Group
                                name="controlled-radio-buttons-group"
                                onChange={handleChangeUserRole}
                                value={userRole}c
                                optionType="button"
                                buttonStyle="solid"
                            >
                                <Radio value="user" checked={userRole === "user"}>User</Radio>
                                <Radio  value="Curator" checked={userRole === "Curator"}>Curator</Radio>
                                {props.isAdmin &&
                                    <Radio value="viewer" checked={userRole === "viewer"}>Viewer</Radio>
                                }
                                <Radio  value="angelInvestor" disabled>Angel Investor</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    <div>
                        {/*<div className='-text xs'>Referal link</div>*/}
                        {/*<div className="-referral">*/}
                        {/*    <div type="text" className='-input referral'>{referralLink === "" ? "" :apiUrl + "/" +referralLink}</div>*/}
                        {/*    {referralLink === "" ?*/}
                        {/*        <button className="-button small-font center disable-margin" type="button" onClick={handleGenerateRefLink}>Generate link</button>*/}
                        {/*        :*/}
                        {/*        <button className="-button small-font center disable-margin" type="button" onClick={handleCopyRefLink}>Copy</button>*/}
                        {/*    }*/}
                        {/*</div>*/}
                        {/*<div className='-text'>Preferred social media</div>*/}

                        {/*<FormControl>*/}
                        {/*    <RadioGroup*/}
                        {/*        row*/}
                        {/*        aria-labelledby="demo-controlled-radio-buttons-group"*/}
                        {/*        name="controlled-radio-buttons-group"*/}
                        {/*        value={preferredSocialMedia}*/}
                        {/*        onChange={handleChangePreferredSocial}*/}
                        {/*    >*/}
                        {/*        <FormControlLabel value="discord" control={<Radio />} label="Discord" />*/}
                        {/*        <FormControlLabel value="telegram" control={<Radio />} label="Telegram" />*/}
                        {/*    </RadioGroup>*/}
                        {/*</FormControl>*/}

                        {/*<a href="https://t.me/FORCEFIbot" className="-link"  target='_blank'>*/}
                        {/*    <div className='-button small-font center'>*/}
                        {/*        Subscribe to Forcefi to receive messages on telegram*/}
                        {/*    </div>*/}
                        {/*</a>*/}
                <div>
                    {userRole === 'Curator' &&
                        <div className='-tags'>
                            {rolesKeys.map((role, key) =>
                                <Tag
                                    key={key}
                                    color={roles[role] ? 'geekblue' : 'default'}
                                    onClick={() => handleRolesChange(role)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {rolesLabels[role]}
                                </Tag>
                            )}
                        </div>
                    }
                </div>
                <div className="-left">
                    <Button style={{marginTop: '8px'}} type="primary" htmlType="submit">Update my profile</Button>
                </div>
                </div>

            </div>
        </form>
    );
}
