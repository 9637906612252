import React, { useState } from 'react';
import { Modal, Input, Button } from 'antd';
import '../../assets/scss/_add-beneficiar-modal.scss';
import BeneficiarManager from "../token/BenificiarManager";
import WhitelistedAddresses from "../token/WhitelistedAddresses";

export default function AddWhiteListedAddresses({ show, close }) {
    const [vestingTrigger, setVestingTrigger] = useState(0);
    const [validWhitelistedAddress, setValidWhitelistedAddress] = useState(false);


    const handleOk = () => {
        close();
    };

    const handleCancel = () => {
        close();
    };

    return (
        <Modal
            title="Add whitelisted addresses"
            visible={show}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    Submit
                </Button>,
            ]}
            closable={false}
        >
            <div>
                <WhitelistedAddresses trigger={vestingTrigger} setValidWhitelistedAddress={setValidWhitelistedAddress}/>
            </div>
        </Modal>
    );
}