import React, {useState} from "react";
import rolesLabels from "../../json/rolesLabels";
import { Tag } from 'antd';

export default function Skills(data) {
    const allRolesStates = Object.keys(data.roles);
    let userRoles = []
    for(let i = 0; i < allRolesStates.length; i++) {
        if (data.roles[allRolesStates[i]]) userRoles.push(rolesLabels[allRolesStates[i]])
    }
    return (
        <>
            <div className="skills-title">Skills</div>
            <div className="expertise">
                {userRoles.map((role, key) =>
                    <Tag className='-role' key={key}>{role}</Tag>
                )}
            </div>
        </>
    );
}