import { useEffect, useState } from 'react';
import * as ethers from "ethers";

export function useNetwork() {
    const [network, setNetwork] = useState(null);

    useEffect(() => {
        async function getNetwork() {
            if (window.ethereum) {
                try {
                    const provider = new ethers.BrowserProvider(window.ethereum);
                    const networkInfo = await provider.getNetwork();
                    setNetwork(networkInfo);
                } catch (error) {
                    console.error("Failed to get network information:", error);
                }
            }
        }
        getNetwork();
    }, []);

    return network;
}
