import React from 'react';
import {NavLink, Outlet, useLocation} from "react-router-dom";
import '../assets/scss/_layout.scss';
import Gitbook from '../assets/icons/footer/gitbook.svg';
import Header from "./Header";
import Discord from "../assets/icons/footer/discord.svg"
import Medium from "../assets/icons/footer/medium.svg"
import Linkedin from "../assets/icons/footer/linkedin.svg"
import Twitter from "../assets/icons/footer/twitter.svg";
import Logo from "../assets/images/forcefi-logo.svg";

export default function Layout() {
    const location = useLocation();
    function Footer() {
        return (
            <div className="footer">
                <div className="-container">
                    <div className="-block">
                        <div className="-main">
                            <img src={Logo} alt="" className="-logo"/>
                            <div className="-socials">
                                <a href="https://www.linkedin.com/company/forcefi/" target='_blank'>
                                    <img className='-img' alt='' src={Linkedin}/>
                                </a>
                                <a href="https://twitter.com/Forcefi_io" target='_blank'>
                                    <img className='-img' alt='' src={Twitter}/>
                                </a>
                                <a href="https://discord.com/invite/CgK8PkyDMW" target='_blank'>
                                    <img className='-img' alt='' src={Discord}/>
                                </a>
                                <a href="https://forcefi.medium.com" target='_blank'>
                                    <img className='-img' alt='' src={Medium}/>
                                </a>
                                <a href='https://docs.forcefi.io/' className='-link' target='_blank'>
                                    <img src={Gitbook} alt="" className='-img' />
                                </a>
                            </div>
                        </div>
                        <div className="-link-list">
                            <NavLink to='/curators' className='-link'>
                                <div className="-item">Our curators</div>
                            </NavLink>
                            <NavLink to='/projects' className='-link'>
                                Projects
                            </NavLink>
                            {/*<div className="-link">About us</div>*/}
                            <a href='https://docs.forcefi.io/' className="-link" target='_blank'>Documentation</a>
                        </div>
                    </div>
                    <div className="-block second">
                      © {(new Date().getFullYear())} Relume. All rights reserved.
                        {/*<div>*/}
                        {/*    <div>Privacy Policy</div>*/}
                        {/*    <div>Terms of Service</div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className={location.pathname === "/" ? "layout-container":"layout-container bg-white"}>
            <header>
              <Header />
            </header>
            <main id='main-content'>
                 <Outlet />
            </main>
            <footer>
                <Footer />
            </footer>
        </div>
    )
}