import React, { useState, useEffect, useRef } from 'react';
import '../assets/scss/_feedback.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { sendRegistrationNotification } from '../controllers/NotificationController';

const Question = ({ title, text, value, onChange, required, showError }) => (
    <div className={showError && required && !value ? "-question error" : "-question"}>
        <div className="-title">
            {title} {required && <span style={{ color: 'red' }}>*</span>}
        </div>
        {text && <div className="-text">{text}</div>}
        <input
            type="text"
            className={`-input ${showError && required && !value ? '-input-error' : ''}`}
            value={value}
            onChange={onChange}
        />
        {showError && required && !value && (
            <div className="-text error">This field is required</div>
        )}
    </div>
);

export default function Feedback() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [questionsCompleted, setQuestionsCompleted] = useState(false);
    const [timer, setTimer] = useState(5);
    const isGoToProjectClicked = useRef(false);
    const [showError, setShowError] = useState(false);

    const [projectName, setProjectName] = useState(params.get('id') || '');
    const [websiteLink, setWebsiteLink] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [achievementsRequest, setAchievementsRequest] = useState('');
    const [challengesDescription, setChallengesDescription] = useState('');
    const [supportDescription, setSupportDescription] = useState('');
    const [doxxedProfiles, setDoxxedProfiles] = useState('');
    const [socialChannels, setSocialChannels] = useState('');
    const [contactPerson, setContactPerson] = useState('');

    const navigate = useNavigate();

    const renderCounter = (counter) => {
        return params.get('id') === null ? counter + 1 : counter;
    };

    const handleSubmit = async () => {
        if (!projectName || !achievementsRequest || !challengesDescription || !contactPerson) {
            setShowError(true);
            return;
        }

        try {
            const formData = {
                projectName,
                websiteLink,
                projectDescription,
                achievementsRequest,
                challengesDescription,
                supportDescription,
                doxxedProfiles,
                socialChannels,
                contactPerson
            };
            if (process.env.REACT_APP_ENV === "PROD") {
                await sendRegistrationNotification(formData);
                params.get("id") === null ? navigate('/') : navigate('/dfo-page?id=' + projectName);
            } else {
                navigate('/dfo-page?id=' + projectName);
            }
        } catch (error) {
            console.error("Error sending registration notification:", error);
        }
    };

    useEffect(() => {
        let interval;

        if (questionsCompleted && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (questionsCompleted && timer === 0) {
            handleSubmit();
        }

        return () => {
            clearInterval(interval);
        };
    });

    const questions = [
        {
            title: `${renderCounter(1)}. Hey ${projectName} team, can you add a link to your website?`,
            value: websiteLink,
            onChange: (e) => setWebsiteLink(e.target.value),
            required: true,
        },
        {
            title: `${renderCounter(2)}. Can you give us a short description of your project?`,
            text: 'Describe the type of dapp, the ecosystem you are building on, and add links to the project\'s docs.',
            value: projectDescription,
            onChange: (e) => setProjectDescription(e.target.value),
            required: false,
        },
        {
            title: `${renderCounter(3)}. What do you hope to achieve/improve with our help?`,
            text: 'E.g. Expand user base, ecosystem-relevant partnerships, product improvements, etc. The more specific you are, the better we\'ll be able to help!',
            value: achievementsRequest,
            onChange: (e) => setAchievementsRequest(e.target.value),
            required: true,
        },
        {
            title: `${renderCounter(4)}. What are your current challenges/bottlenecks?`,
            text: 'E.g. Marketing, BD, team building, fundraising, community engagement, etc.',
            value: challengesDescription,
            onChange: (e) => setChallengesDescription(e.target.value),
            required: true,
        },
        {
            title: `${renderCounter(5)}. What type of support are you looking for?`,
            text: 'Describe the type of collaboration or support you expect from us. Specify any resources you may need, such as mentorship, funding, intros, technical support, etc.',
            value: supportDescription,
            onChange: (e) => setSupportDescription(e.target.value),
            required: false,
        },
        {
            title: `${renderCounter(6)}. Is your team doxxed? If so please add their LinkedIn handles below`,
            text: 'If your team is pseudonymous, just enter their Twitter handles.',
            value: doxxedProfiles,
            onChange: (e) => setDoxxedProfiles(e.target.value),
            required: false,
        },
        {
            title: `${renderCounter(7)}. Please add links to your social channels`,
            text: 'Twitter, Discord, and any other accounts you are building an audience on',
            value: socialChannels,
            onChange: (e) => setSocialChannels(e.target.value),
            required: false,
        },
        {
            title: `${renderCounter(8)}. Person of contact?`,
            text: 'Please add the TG name and/or email of the person we can stay in contact with',
            value: contactPerson,
            onChange: (e) => setContactPerson(e.target.value),
            required: true,
        },
    ];

    const handleValidation = () => {
        if (!projectName || !achievementsRequest || !challengesDescription || !contactPerson) {
            setShowError(true);
            return false;
        }
        return true;
    };

    return (
        <div className="container">
            {!questionsCompleted ? (
                <div className='questions'>
                    {params.get("id") === null && (
                        <Question
                            title='1. What is the name of your project?'
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            required={true}
                            showError={showError}
                        />
                    )}
                    {questions.map((question, index) => (
                        <Question
                            key={index}
                            title={question.title}
                            text={question.text}
                            value={question.value}
                            onChange={question.onChange}
                            required={question.required}
                            showError={showError}
                        />
                    ))}
                    <button
                        className='-button'
                        onClick={() => {
                            if (handleValidation()) {
                                setQuestionsCompleted(true);
                            }
                        }}
                    >
                        Submit
                    </button>
                </div>
            ) : (
                <div className="questions offset center">
                    <div className="-title">Thank you for your response!</div>
                    <div className='-text'>
                        Redirecting to project in {timer} seconds...
                    </div>
                    <button
                        className="-button"
                        onClick={() => {
                            isGoToProjectClicked.current = true;
                            handleSubmit();
                        }}
                    >
                        Go to project
                    </button>
                </div>
            )}
        </div>
    );
}
