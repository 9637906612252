const erc20InvestmentTokens = {
    // SEPOLIA
    11155111: {
        USDT: "0xC09291B33A6E7ba4D7c293D58a6683C1f9F2946C",
        USDC: "0x3a9725495A7552a4de196962784ba073f0ac89fc",
        DAI: "0xdf3477E7a8C21dAa0AB5B8089466FfFAD53d8B3b",

        FUNDRAISING_ADDRESS: "0x8A52CDCD89FAc778B2607085B4507e6CfE7e898A",
        VESTING_ADDRESS: "0x80026f366Fd5Fd4913CAcdF29d4B69d789aF99Ef",
        PACKAGE_ADDRESS: "0xec93e09a4693D443aFA073B026adA60584e6ef49",
        STAKING_ADDRESS: "0xbC48106039FaBA06BD5777a68bD24f85ddac878e",
        FORC_TOKEN_ADDRESS: "0x290E374362b6d36A0cfF86d682Db57845c913657",

        SIMPLE_FACTORY_ADDRESS: "0xD82b96672237c11BBaF6377ca7De73FCf3ecE8CD",
        PAUSABLE_FACTORY_ADDRESS: "0x3915F8d5a94c7b48Bef0A22bc4d9C8B845E4E325"
    },

    // ARBITRUM-SEPOLIA
    421614: {
        USDT: "0xd9989f31B8b091015E47d2E75a32d529F90d29cC",
        USDC: "0xAF7CA3bBdb400B0B23b7a7Db8f549a46D8024b61",
        DAI: "0x58bC11229341821fa3C22630aEE8cE05D5c49d3F",

        FUNDRAISING_ADDRESS: "0x357efaEf6fDdb79be305a01aBF15A4317004E23E",
        VESTING_ADDRESS: "0xf2b2AFfa7b418B28699289653855cc02e7E2eC98",
        PACKAGE_ADDRESS: "0xF7c986A94Dc4F123893cBD3c814A94E9D4d0D99B",
        STAKING_ADDRESS: "0xD0dDfC08e77C7AD07bf31C4bA680AF67954127d8",

        SIMPLE_FACTORY_ADDRESS: "0xfcD3327Ce48B73006719919f597D8A181D1fBde0",
        PAUSABLE_FACTORY_ADDRESS: "0x6B6446D42506890CC1860D578898C52De02929A7"
    },

    // LINEA-SEPOLIA
    59141: {
        USDT: "0x1A6B61331bC039747f00091f1acf593C3Cd2AB76",
        USDC: "0x58bC11229341821fa3C22630aEE8cE05D5c49d3F",
        DAI: "0xd9989f31B8b091015E47d2E75a32d529F90d29cC",

        FUNDRAISING_ADDRESS: "0xD0dDfC08e77C7AD07bf31C4bA680AF67954127d8",
        VESTING_ADDRESS: "0x6cBd776110e2BF425ffCD12Dd2eF78a1f8f090A1",
        PACKAGE_ADDRESS: "0x6B6446D42506890CC1860D578898C52De02929A7",

        SIMPLE_FACTORY_ADDRESS: "0x357efaEf6fDdb79be305a01aBF15A4317004E23E",
        PAUSABLE_FACTORY_ADDRESS: "0xf2b2AFfa7b418B28699289653855cc02e7E2eC98"
    },

};

export default erc20InvestmentTokens;
