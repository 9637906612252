import React, { useState } from 'react';
import { Button, Flex, Input, message } from 'antd';
import { z } from 'zod';
import {saveCustomizedSale} from "../../controllers/FundraisingController";
const { TextArea } = Input;

const saleSettingsSchema = z.object({
    title: z.string().min(1, 'Title is required'),
    keyBenefits: z.string().min(1, 'Key Benefits Title is required'),
    description: z.string().min(1, 'Project Description is required'),
    benefits: z.array(z.string().min(1, 'Benefit is required')).min(1, 'At least 1 benefit is required').max(5, 'You can add up to 5 benefits')
});

export default function SaleSettings({ close, saleId }) {
    const [benefits, setBenefits] = useState(['']);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [keyBenefits, setKeyBenefits] = useState('');
    const [errors, setErrors] = useState({});
    // const handleSaveCustomizedSale = async () => {
    //     const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    //     const selectedAccount = accounts[0];
    //     const data = {
    //         ownerAddress: selectedAccount,
    //         projectName: title,
    //         address: saleId,
    //
    //         title: title,
    //         description: description,
    //         keyBenefits: keyBenefits,
    //         saleBackground: saleBackground,
    //         background: background
    //     };
    //     try {
    //         const result = await saveCustomizedSale(data);
    //         console.log('Fundraising saved successfully:', result);
    //     } catch (error) {
    //         console.error('Error saving fundraising:', error);
    //     }
    // };
    const validateField = (fieldName, value) => {
        try {
            saleSettingsSchema.pick({ [fieldName]: true }).parse({ [fieldName]: value });
            setErrors(prevErrors => ({ ...prevErrors, [fieldName]: undefined }));
        } catch (e) {
            if (e instanceof z.ZodError) {
                setErrors(prevErrors => ({ ...prevErrors, [fieldName]: e.errors[0].message }));
            }
        }
    };

    const handleBenefitChange = (index, newValue) => {
        const newBenefits = [...benefits];
        newBenefits[index] = newValue;
        setBenefits(newBenefits);

        try {
            z.string().min(1, 'Benefit is required').parse(newValue);
            setErrors(prevErrors => ({ ...prevErrors, [`benefit${index}`]: undefined }));
        } catch (e) {
            setErrors(prevErrors => ({ ...prevErrors, [`benefit${index}`]: e.errors[0].message }));
        }
    };

    const handleTitleChange = (value) => {
        setTitle(value);
        validateField('title', value);
    };

    const handleDescriptionChange = (value) => {
        setDescription(value);
        validateField('description', value);
    };

    const handleKeyBenefitsChange = (value) => {
        setKeyBenefits(value);
        validateField('keyBenefits', value);
    };

    const addBenefit = () => {
        if (benefits.length < 5) {
            setBenefits([...benefits, '']);
        } else {
            message.error('You can add up to 5 benefits');
        }
    };

    const removeBenefit = (index) => {
        if (benefits.length > 1) {
            const newBenefits = benefits.filter((_, i) => i !== index);
            setBenefits(newBenefits);
        } else {
            message.error('At least 1 benefit is required');
        }
    };

    const saveChanges = () => {
        const formData = { title, keyBenefits, description, benefits };

        try {
            saleSettingsSchema.parse(formData);
            message.success('Changes saved successfully!');
            close();
        } catch (e) {
            if (e instanceof z.ZodError) {
                const errorObj = {};
                e.errors.forEach(err => {
                    const field = err.path[0];
                    if (field === 'benefits') {
                        const index = err.path[1];
                        errorObj[`benefit${index}`] = err.message;
                    } else {
                        errorObj[field] = err.message;
                    }
                });
                setErrors(errorObj);
            }
        }
    };

    return (
        <div className="-edit">
            <div className="-title">Edit Selected Project Information with the Editor Mode</div>

            <div className="-text">Title</div>
            <Input
                placeholder="$SOL Token Sale Page"
                value={title}
                onChange={e => handleTitleChange(e.target.value)}
            />
            {errors.title && <div className="-text error">{errors.title}</div>}

            <div className="-text">Project Description</div>
            <TextArea
                placeholder="$SOL Token Sale Page"
                value={description}
                onChange={e => handleDescriptionChange(e.target.value)}
            />
            {errors.description && <div className="-text error">{errors.description}</div>}

            <div className="-text">Key Benefits Title</div>
            <Input
                placeholder="$SOL Token Sale Page"
                value={keyBenefits}
                onChange={e => handleKeyBenefitsChange(e.target.value)}
            />
            {errors.keyBenefits && <div className="-text error">{errors.keyBenefits}</div>}

            {benefits.map((benefit, index) => (
                <div key={index} style={{ marginBottom: '10px' }}>
                    <div className="-text">Benefit {index + 1}</div>
                    <Flex gap={6}>
                        <Input
                            placeholder={`Benefit ${index + 1}`}
                            value={benefit}
                            onChange={(e) => handleBenefitChange(index, e.target.value)}
                            style={{ width: '96%' }}
                        />
                        {benefits.length > 1 && (
                            <Button style={{ width: '4%' }} onClick={() => removeBenefit(index)}>
                                X
                            </Button>
                        )}
                    </Flex>
                    {errors[`benefit${index}`] && <div className="-text error">{errors[`benefit${index}`]}</div>}
                </div>
            ))}

            {errors.benefits && <div className="-text error">{errors.benefits}</div>}

            <div style={{ marginTop: '20px' }}>
                <Button type="dashed" onClick={addBenefit}>
                    Add Benefit
                </Button>
            </div>

            <div style={{ marginTop: '20px' }}>
                <Button type="primary" onClick={close}>
                    Close
                </Button>
            </div>

            <div style={{ marginTop: '20px' }}>
                <Button type="primary" onClick={saveChanges}>
                    Save Changes
                </Button>
            </div>
        </div>
    );
}
