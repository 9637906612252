import React, {useEffect, useState} from 'react';
import "../assets/scss/_search.scss"
import "../assets/scss/_layout.scss"
import {useNavigate, useParams} from "react-router-dom";
// import AllChains from "../assets/icons/AllChains.svg"
import {useNetwork} from "wagmi";
import CompanyCard from "../components/CompanyCard";
import ChainFilter from "../components/ChainFilter";
import {factoryAddresses} from "../utils/metamaskNetworkConfigs";
import {Input, Pagination, Select} from 'antd';
import "../assets/scss/_company-card.scss"
import {getDfoCompaniesV2, getDfoCompanyLogo} from "../controllers/ProjectsController";

export default function Projects() {
    const navigate = useNavigate();
    const isProd = process.env.REACT_APP_ENV === "PROD";
    const { chain } = useNetwork()

    const factoryInfo = chain ? factoryAddresses[chain.network] || { isMainnet: true } : {isMainnet: true};
    const { isMainnet } = factoryInfo;

    const { path } = useParams();
    const [showLoader, setShowLoader] = useState(true);

    const [projectStatus, setProjectStatus] = useState('All');
    const [listingTier, setListingTier] = useState('All');
    const [itemsPerPage, setItemsPerPage ] = useState(9);
    const [currentPage, setCurrentPage ] = useState(1);
    const [pageCount, setPageCount ] = useState(1);

    const [data, setData ] = useState([]);
    const [newData, setNewData ] = useState({data: [], page: 1, status: "All", listingTier: "All", pageSize: 6, chain: "AllChains"});

    const [cachedImages, setCachedImages ] = useState({});
    const [newCachedImages, setNewCachedImages ] = useState([]);
    const [updateTrigger, setUpdateTrigger ] = useState(0);
    const [searchInput, setSearchInput] = useState(path || '');

    const [queryChain, setChain ] = useState("AllChains");
    const { Search } = Input;

    useEffect(() => {
        setShowLoader(true);
    }, [])

    useEffect(() => {
        setCachedImages({...cachedImages, [newCachedImages.companyName]: newCachedImages.data})
    }, [])

    useEffect(() => {
        if (newData.page === currentPage && newData.status === projectStatus && newData.pageSize === itemsPerPage && newData.chain === queryChain) {
         setData(newData.data.map((item, key) => <CompanyCard item={item} key={key} />))
        }
        setShowLoader(false)
    }, [newData, updateTrigger, currentPage, itemsPerPage, projectStatus, queryChain])

    useEffect(() => {
        const controller = new AbortController();
        controller.abort();

        const getData = async (page, status, listingTier, pageSize, queryChain) => {
            const companyName = path === undefined ? "" : path
            let result;
            setShowLoader(true);
            let query;
            if (chain && !isMainnet){
                queryChain = chain.network;
                setChain(chain.network);
                // setCurrentPage(1)
            }
            query = {
                params: {
                    companyName: companyName,
                    pageNumber: page,
                    pageSize: pageSize
                },
            };

            if (queryChain !== "AllChains"){
                query.params.network = queryChain;
            }
            if (listingTier !== "All") {
                query.params.listingTier = listingTier;
            }
            if (status !== "All"){
                query.params.projectStatus = status;
            }

            // setData(<div className='-empty'></div>);
            try {
                const companiesData = await getDfoCompaniesV2(query);
                result = companiesData.transformedData.map((obj) => {
                    return { ...obj, logo: "" };
                });

                setNewData({ data: result, page: page, status: status, listingTier: listingTier, pageSize: pageSize, chain: queryChain });
                setPageCount(Math.ceil(companiesData.docCount / itemsPerPage));

                if (result.length > 0) {
                    for (let i = 0; i < result.length; i++) {
                        const companyName = result[i].companyName;
                        const network = result[i].network;

                        if (cachedImages[companyName] === undefined) {
                            const logoData = await getDfoCompanyLogo(companyName, network);
                            result[i].logo = logoData;

                            setNewData({ data: result, page: page, status: status, listingTier: listingTier, pageSize: pageSize, chain: queryChain });
                            setUpdateTrigger(i);
                            setNewCachedImages({ companyName : companyName, data: logoData });
                            setShowLoader(false);
                        } else {
                            result[i].logo = cachedImages[companyName];
                            setNewData({ data: result, page: page, status: status, listingTier: listingTier, pageSize: pageSize, chain: queryChain });
                            setUpdateTrigger(-1);
                        }
                    }
                } else {
                    setShowLoader(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setShowLoader(false);
            }
        }
        getData(currentPage, projectStatus, listingTier, itemsPerPage, queryChain);
    }, [])

    useEffect(() => {
        if (isMainnet){
            setChain("AllChains")
        }
    },[isMainnet])

    const handleChangeFilter = value => {
        setCurrentPage(1);
        setProjectStatus( value);
    }

    const handleChangeListingFilter = value => {
        setCurrentPage(1);
        setListingTier(value);
    }

    const handleChangeChain = chain => {
        setCurrentPage(1);
        setChain(chain)
    }

    const handleChangePageSize = (page, pageSize) => {
        setCurrentPage(page);
        if (pageSize !== itemsPerPage) {
            setItemsPerPage(pageSize)
            setCurrentPage(1)
        }
    };

    const handleOnEnter = e => {
        if (e.key === "Enter") handleSearch();
    };

    const handleSearch = () => {
        setSearchInput(searchInput);
        navigate('/projects/' + searchInput);
    }

    return (
        <div className="main-container">
            <div className="projects-title">Browse through all projects</div>
            <div className="search-container">
                {chain ?
                    isMainnet ?
                        <ChainFilter
                            queryChain={queryChain}
                            handleChangeChain={handleChangeChain}
                            isProd={isProd}
                        /> : <div/>
                    :
                    <ChainFilter
                        queryChain={queryChain}
                        handleChangeChain={handleChangeChain}
                        isProd={isProd}
                    />
                }
                <div className="filter-container">
                    <Search
                        placeholder="search by product name"
                        className="-input"
                        enterButton
                        value={searchInput}
                        onKeyPress={handleOnEnter}
                        onChange={(event) => setSearchInput(event.currentTarget.value)}
                        onSearch={handleSearch}
                    />
                    <Select
                        defaultValue={'All'}
                        className="-input"
                        onChange={handleChangeListingFilter}
                        options={[
                            { value: 'All', label: 'All tiers' },
                            { value: 'Starter', label: 'Starter' },
                            { value: 'Explorer', label: 'Explorer' },
                            { value: 'Accelerator', label: 'Accelerator' },
                        ]}
                    />
                    <Select
                        defaultValue={'All'}
                        className="-input"
                        onChange={handleChangeFilter}
                        options={[
                            { value: 'All', label: 'All statuses' },
                            { value: 'Listed', label: 'Listed' },
                            { value: 'PreLaunch', label: 'Prelaunch' },
                            { value: 'Active', label: 'Active' },
                            { value: 'Completed', label: 'Completed' },
                        ]}
                    />
                </div>

                <div className='search-page-wrapper'>
                    {showLoader &&
                        <div className="-cards">
                            {[...Array(itemsPerPage)].map((_, index) => (
                                <div className='company-card skeleton' key={index}> </div>

                            ))}
                        </div>

                    }
                    { !showLoader &&
                        <>
                            {(data.length === 0) ?
                                <div className='-no-results'>
                                    No results found
                                </div>
                                :
                                 <>
                                     <div className="-cards">
                                        {data}
                                     </div>
                                     {pageCount > 1 &&
                                         <div className="pagination">
                                             <Pagination
                                                 current={currentPage}
                                                 total={pageCount * itemsPerPage}
                                                 pageSize={itemsPerPage}
                                                 pageSizeOptions={[9,12,15]}
                                                 onChange={(page, pageSize) => handleChangePageSize(page, pageSize)}
                                             />
                                         </div>
                                     }
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}
