import React, { useState } from 'react';
import { Modal, Input, Button } from 'antd';
import '../../assets/scss/_add-beneficiar-modal.scss';
import BeneficiarManager from "../token/BenificiarManager";
import CSVUploader from "../CSVUploader";

export default function AddBeneficiar({ show, close }) {
    const [beneficiaries, setBeneficiaries] = useState([{ address: '', amount: '', }]);
    const [isBenificiarValid, setIsBenificiarValid] = useState(false);
    const [benificiarTrigger, setBenificiarTrigger] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const handleBeneficiarChange = (index, value) => {
        setBeneficiaries(value);
    }
    const handleOk = () => {
        console.log(isBenificiarValid)
        console.log([ ...csvData, ...beneficiaries])
    };

    const handleCancel = () => {
        close();
    };

    return (
        <Modal
            title="Add beneficiary to project"
            visible={show}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    Submit
                </Button>,
            ]}
            closable={false}
        >
            <div>
                <BeneficiarManager
                    beneficiars={beneficiaries}
                    handleBeneficiarChange={handleBeneficiarChange}
                    index={0}
                    setIsBenificiarValid={setIsBenificiarValid}
                    validateForm={() => console.log('validate')}
                    benificiarTrigger={benificiarTrigger}
                />
            </div>
            <CSVUploader csvData={csvData} setCsvData={setCsvData}/>
        </Modal>
    );
}
