import React, {useState} from 'react';
import Modal from 'react-modal';
import "../../assets/scss/_modal.scss"
import axios from "axios";
import {useAccount, useContractRead} from "wagmi";
import {toast} from "react-toastify";

const customStyles = {
    content: {
        width: 600,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0px',
        marginRight: '-50%',
        border: '2px solid #000',
        transform: 'translate(-50%, -50%)',
    },
};

export default function TestnetUserWhitelistModal(props) {

    const { address } = useAccount();

    const[whitelistAddress, setWhitelistAddress] = useState("");

    const[whitelistFromVRF, setWhitelistFromVRF] = useState("");

    const handleChangeAddress = (event) => {
        setWhitelistAddress(event.target.value);
    }

    const handleSubmit = async () => {
        const whitelistAddresses = whitelistAddress.split(',');
        submitTestnetUsers(whitelistAddresses);
    }

    const handleSubmitVRFWhitelist = async () => {
        submitTestnetUsers(whitelistFromVRF)
    }

    const submitTestnetUsers = async (whitelistAddresses) => {
        await axios.post(process.env.REACT_APP_API_ENDPOINT + '/secured/api/setAsCurator', {
            requestAddress: address,
            walletAddress: whitelistAddresses
        })
            .then((response) => {
                toast.success("Successfully added as curator")
                props.close()
            })
            .catch((error) => console.log(error));
    }

    return (
        <div>
            <Modal
                isOpen={props.show}
                onRequestClose={props.close}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className='modal-content'>
                    <form>
                        Set address as curator
                    </form>
                </div>
                    <input
                        type="text"
                        name="whitelistAddress"
                        className="-input"
                        placeholder="address"
                        onChange={handleChangeAddress}
                     />

                    <button
                        type="submit"
                        className='-button'
                        onClick={handleSubmit}
                    >
                        Add address as curator
                    </button>
            </Modal>
        </div>
    );
}
