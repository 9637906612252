import './App.css';
import React, {useEffect} from 'react';
import {Outlet, useLocation} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
// import {createConfig, useAccount, WagmiConfig} from 'wagmi'
import axios from "axios";
import deleteIndexedDB from "./utils/indexedDb";
import {CuratorsAccessProvider} from "./contexts/CuratorsAccess";
import {Navigate, Route, Routes} from "react-router";
import ThankYouWindow from "./pages/ThankYouWindow";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Cabinet from "./pages/Cabinet";
import DfoPage from "./pages/DfoPage";
import Projects from "./pages/Projects";
import ErrorBoundary from "./utils/ErrorBoundary";
import Registration from "./pages/Registration";
import Feedback from "./pages/Feedback";
import CuratorsPage from "./pages/CuratorsPage";
import Page401 from "./pages/Page401";
import {ToastContainer} from "react-toastify";
import {createWeb3Modal, defaultWagmiConfig} from '@web3modal/wagmi/react'

import {WagmiConfig} from 'wagmi'
import {arbitrum, arbitrumSepolia, bsc, lineaTestnet, mainnet, optimism, polygon, zkSync, sepolia, linea} from 'viem/chains'
import Partners from "./pages/Partners";
import TokenCreation from "./pages/TokenCreation";
import Vesting from "./pages/Vesting";
import Fundraising from "./pages/Fundraising";
import Sale from "./pages/Sale";
import Referral from "./pages/Referral";
import TokenControl from "./pages/TokenControl";

// 1. Get projectId
const projectId = process.env.REACT_APP_WALLET_CONNECT_ID

// 2. Create wagmiConfig
const metadata = {
    name: 'web3-modal-setup',
    description: 'Web3 Modal Example',
}

let chains;
if (process.env.REACT_APP_ENV === 'DEV'){
    chains = [sepolia, arbitrumSepolia, lineaTestnet]
} else {
    chains = [mainnet, arbitrum, optimism, zkSync, polygon, bsc, linea]
}
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains })

export default function App() {
    const { pathname } = useLocation();
    useEffect(() => {
        document.documentElement.scrollTo({top:0 ,left:0, behavior: "auto"});
    }, [pathname]);

    const ProtectedRoute = ({ element: Element, ...rest }) => {
        return <Outlet />;
    }

    useEffect(() => {
        setTimeout(() => deleteIndexedDB(), 1000);
    }, []);

    axios.interceptors.request.use(function (config) {
        config.headers.Authorization = localStorage.getItem('authToken');
        return config;
    });

    return (
        <>
            <WagmiConfig config={wagmiConfig}>
                <CuratorsAccessProvider>
                    <Routes>
                    <Route path="/gib-email-sire" element={<ThankYouWindow />}/>
                      <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                          <Route element={<ProtectedRoute />}>
                                <Route path='cabinet' element={<Cabinet />} />
                                <Route path='dfo-page' element={<DfoPage />} />
                                <Route path='partners' element={<Partners />} />
                                <Route path='projects' element={<Projects />} />
                                <Route path='projects/:path' element={<Projects />} />
                                <Route path='registration' element={<ErrorBoundary><Registration /></ErrorBoundary>} />
                                <Route path='feedback' element={<Feedback />} />
                                <Route path='curators' element={<CuratorsPage />} />
                                <Route path='create-token' element={<TokenCreation />} />
                                <Route path='vesting' element={<Vesting />} />
                                <Route path='fundraising' element={<Fundraising />} />
                                <Route path='sale' element={<Sale />} />
                                <Route path='token-control' element={<TokenControl />} />
                                <Route path='referral' element={<Referral />} />
                            </Route>
                            <Route path='401' element={<Page401 />} />
                            <Route path='*' element={<Navigate to="/" replace />} />
                        </Route>
                    </Routes>
                </CuratorsAccessProvider>
            </WagmiConfig>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                icon={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}
