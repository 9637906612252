import axios from "axios";
import {toast} from "react-toastify";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export async function updateListingTierInDb (listingTier, companyName) {
    return await axios.post(apiUrl + '/secured/api/updateListingTier', {
        listingTier: listingTier,
        companyName: companyName
    })
        .then(response => {

        })
}
