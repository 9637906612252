import React, {useState} from 'react';
import {useSignMessage} from "wagmi";
import {useLocation, useNavigate} from "react-router-dom";
import {getNonce} from "../controllers/ServerController";

import * as ethers from "ethers";

export const useAuthenticate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const EXPIRATION_DATE_TOKEN_IN_SECONDS = 2592000000

    const authenticate = async (address) => {
        try {
            const response = await getNonce(address);
            signMessageEthers(response.data.message, response.data.tempToken);
        } catch (error) {
            console.error("Error during authentication", error);
        }
    };

    let provider;

    if (window.ethereum) {
        provider = new ethers.BrowserProvider(window.ethereum);
    } else {
        console.error("No browser or Ethereum provider available.");
    }

    const signMessageEthers = async(signatureMessage, signatureToken) => {
        const signer = await provider.getSigner();
        let signature = await signer.signMessage( signatureMessage );
        await authenticateOnServer(signature, signatureToken)
    }

    const[signatureMsg, setSignatureMsg] = useState("")

    const authenticateOnServer = async(signature, signatureToken) => {
        let opts = {
            method: 'POST',
            // body: JSON.stringify({"referredBy": localStorage.getItem("referral").replace(/"/g, '')}),
            body: JSON.stringify({"referredBy": ""}),
            headers: {
                'Content-Type': "application/json",
                "Authorization": `Bearer ${signatureToken}`
            }
        }
        let res = await fetch(`/verify?signature=${signature}`, opts)
        let resBody = await res.json();

        localStorage.setItem('authToken', "Bearer " + resBody.token);
        const expirationDate = Date.now() + EXPIRATION_DATE_TOKEN_IN_SECONDS
        localStorage.setItem('expirationDate', expirationDate.toString());

        if(location.pathname === "/curators"){
            navigate('/curators')
        }
    }

    return {
        authenticate,
        authenticateOnServer,
        signatureMsg,
        setSignatureMsg,
    };
}
