import { createContext, useContext, useEffect, useState } from 'react';
import axios from "axios";
import {useAccount} from "wagmi";
import {getUserProfile} from "../controllers/UserProfileController";
import {getDFoCompaniesCountByOwner} from "../controllers/ProjectsController";

const CuratorsAccess = createContext();

export const useCuratorsAccess = () => {
    return useContext(CuratorsAccess);
};

export const CuratorsAccessProvider = ({ children }) => {
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const { address } = useAccount();
    const [hasCurratorAccess, setHasCurratorAccess] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getUserAccessToCurators = async () => {
        try {
            const response = await getDFoCompaniesCountByOwner(address);
            if (response.data > 0) {
                setHasCurratorAccess(true);
            } else {
                const userProfileResponse = await getUserProfile(address);
                if (userProfileResponse.data.userRole === "Viewer" || userProfileResponse.data.isCurator) {
                    setHasCurratorAccess(true);
                } else {
                    setHasCurratorAccess(false);
                }
            }
        } catch (error) {
            console.error("Error fetching data", error);
            setHasCurratorAccess(false);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getUserAccessToCurators();
    }, []);

    return (
        <CuratorsAccess.Provider value={{hasCurratorAccess , isLoading} } >
            {children}
        </CuratorsAccess.Provider>
    );
};
