import React, {useEffect, useState} from 'react';
import { Table, Button } from 'antd';
import {getProjectTokens} from "../../../ethers/ContractFactory";
import {getTokenDecimals, getTokenName, getTokenTicker, getTotalSupply} from "../../../ethers/ERC20Contract";
import {Link, useLocation} from "react-router-dom";
import Web3 from "web3";
import ShortAddress from "../../elements/ShortAddress";

const TokenTable = (props) => {

    // const location = useLocation();
    // const projectName = location.state.projectName;

    const[dataSource, setDataSource] = useState([])

    useEffect(() => {
        handleGetProjectTokens()
    },[])

    const columns = [
        {
            title: 'Token name',
            dataIndex: 'tokenName',
            key: 'tokenName',
        },
        {
            title: 'Ticker',
            dataIndex: 'ticker',
            key: 'ticker',
        },
        {
            title: 'Total Supply',
            dataIndex: 'totalSupply',
            key: 'totalSupply',
        },
        {
            title: 'Token address',
            dataIndex: 'tokenAddress',
            key: 'tokenAddress',
            render: (text, record) => (
                <ShortAddress address={text} />
            ),
        },
        {
            title: '',
            dataIndex: 'tokenControl',
            key: 'tokenControl',
            render: (text, record) => (
                <Link to={`/token-control?id=${record.tokenAddress}`}>
                    <Button style={{ minWidth: '100px' }}>
                        Token control
                    </Button>
                </Link>
            ),
        },
    ];

    const handleGetProjectTokens = async () => {
        const erc20Addresses = await getProjectTokens(props.projectName);



        const dataFromBlockchain = [];
        for (const erc20Address of erc20Addresses ){
            const decimals = await getTokenDecimals(erc20Address);
            console.log('DECIMALS  ' + decimals)

            const a = (await getTotalSupply(erc20Address))
            console.log('CONVERTING ' + Web3.utils.fromDecimal((await getTotalSupply(erc20Address)).toString(), decimals),)
            dataFromBlockchain.push({
                index: 1,
                ticker: await getTokenTicker(erc20Address),
                totalSupply: Web3.utils.fromWei((await getTotalSupply(erc20Address)).toString(), "ether"),
                tokenName: await getTokenName(erc20Address),
                tokenAddress: erc20Address,
            })
        }
        setDataSource(dataFromBlockchain)
    }

    const convertAmount = (amount, decimals) => {
        const conversionFactor = 10 ** decimals;
        return amount / conversionFactor;
    };

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            rowKey={(record, index) => index}
        />
    );
};

export default TokenTable;
