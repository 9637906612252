import React, {useEffect, useState} from 'react';
import { Table, Button } from 'antd';
import {getProjectTokens} from "../../../ethers/ContractFactory";
import {getTokenName, getTokenTicker, getTotalSupply} from "../../../ethers/ERC20Contract";
import {getIndividualVesting, getProjectVestings, getVestingPlan, releaseVestedTokens} from "../../../ethers/Vesting";
import LoadingModal from "../../modals/LoadingModal";
import ShortAddress from "../../elements/ShortAddress";
import Web3 from "web3";
import AddBeneficiar from "../../modal2/AddBeneficiar";

const VestingTable = (props) => {

    const[dataSource, setDataSource] = useState([])
    const[isLoading, setIsLoading] = useState(false)
    const[showAddBenificiar, setShowAddBenificiar] = useState(false)

    useEffect(() => {
        handleGetVestingData()
    },[props.projectName])

    const handleReleaseToken = async (vestingIdx) => {
        setIsLoading(true)
        await releaseVestedTokens(vestingIdx);
        setIsLoading(false)
    }

    const columns = [
        {
            title: 'Token address',
            dataIndex: 'tokenAddress',
            key: 'tokenAddress',
            render: (text, record) => (
                <ShortAddress address={record.tokenAddress} />
            ),
        },
        {
            title: 'Vesting label',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Total tokens locked',
            dataIndex: 'totalTokenAmount',
            key: 'totalTokenAmount',
        },
        {
            title: 'Tokens allocated',
            dataIndex: 'tokenAllocated',
            key: 'tokenAllocated',
        },
        {
            title: 'Individual tokens allocated',
            dataIndex: 'individualTokensAllocated',
            key: 'individualTokensAllocated'
        },
        {
            title: 'Individual plan tokens released',
            dataIndex: 'tokensReleased',
            key: 'tokensReleased',
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <div>
                    { props.isAdmin &&
                        <div>
                            <Button onClick={() => setShowAddBenificiar(true)}>
                                Add beneficiars
                            </Button>
                        </div>
                    }
                </div>
            ),
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <div>
                    {
                        record.individualTokensAllocated > 0 &&
                        <Button onClick={() => handleReleaseToken(record.vestingIdx)}>
                            Release tokens
                        </Button>
                    }
                </div>
            ),
        },
    ];

    const handleGetVestingData = async () => {
        const vestingData = await getProjectVestings(props.projectName);

        const dataFromBlockchain = [];

        for(const vesting of vestingData) {
            const vestingPlan = await getVestingPlan(vesting);

            const individualVesting = await getIndividualVesting(vesting)
            dataFromBlockchain.push({
                index: 1,
                tokenAddress: vestingPlan[0],
                label: vestingPlan[2],
                totalTokenAmount: vestingPlan[9].toString(),
                tokenAllocated: Web3.utils.fromWei(vestingPlan[10].toString(), "ether"),
                vestingIdx: vesting,
                tokensReleased: Web3.utils.fromWei(individualVesting[1].toString(), "ether"),
                individualTokensAllocated: Web3.utils.fromWei(individualVesting[0].toString(), "ether")
            })
        }

        setDataSource(dataFromBlockchain)
    }

    return (
        <>
            <AddBeneficiar show={showAddBenificiar} close={() => setShowAddBenificiar(false)}/>
            <LoadingModal  show={isLoading} text='Vesting creation in process...' />
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                rowKey={(record, index) => index}
            />
        </>
    );
};

export default VestingTable;
