import * as ethers from "ethers";
import ForcefiPackage from "../abis/ForcefiPackage.json";
import erc20InvestmentTokens from "../constants/ERC20InvestmentTokens";
import {getSigner} from "./utils/GetSigner";

const buyPackage = async (projectName, packageLabel, investmentTokenAddress, referralAddress) => {
    try {
        const signer = getSigner()
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork()

        const packageContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].PACKAGE_ADDRESS, ForcefiPackage, signer);

        let tier;
        const packageBoughtPromise = new Promise((resolve, reject) => {
            packageContract.on("PackageBought", (arg1, arg2, arg3, event) => {
                console.log("Event received:", arg1, arg2, arg3);
                console.log("Event:", event);
                tier = arg2;
                resolve(arg2);
            });
        });

        const tx = await packageContract.buyPackage(projectName, packageLabel, investmentTokenAddress, referralAddress);

        await tx.wait();
        await packageBoughtPromise;

        return tier;

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}
export default buyPackage;

export async function getChainlinkDataFeedLatestAnswer (investmentTokenAddress) {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);

        const network = await provider.getNetwork();

        const erc20TokenContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].PACKAGE_ADDRESS, ForcefiPackage, provider);

        return await erc20TokenContract.getChainlinkDataFeedLatestAnswer(investmentTokenAddress);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

