import * as ethers from "ethers";

// export async function getSigner() {
//     try {
//         const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
//         const selectedAccount = accounts[0];
//         const provider = new ethers.BrowserProvider(window.ethereum);
//         return await provider.getSigner(selectedAccount);
//     } catch (error) {
//         console.error('Error getting signer:', error);
//         throw error; // Re-throw the error to be handled by the calling function
//     }
// }

export async function getSigner() {
    try {

        const provider = new ethers.BrowserProvider(window.ethereum);
        return await provider.getSigner();
    } catch (error) {
        console.error('Error getting signer:', error);
        throw error; // Re-throw the error to be handled by the calling function
    }
}
