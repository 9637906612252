import React, {useState} from 'react';
import Modal from 'react-modal';
import "../../assets/scss/_modal.scss"
import axios from "axios";
import {useAccount, useContractRead} from "wagmi";
import {toast} from "react-toastify";

const customStyles = {
    content: {
        width: 600,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0px',
        marginRight: '-50%',
        border: '2px solid #000',
        transform: 'translate(-50%, -50%)',
    },
};

export default function ProjectRemoveModal(props) {

    const { address } = useAccount();

    const[projectName, setProjectName] = useState("");

    const handleRemoveProject = (event) => {
        setProjectName(event.target.value);
    }

    const handleSubmit = async () => {
        await submitRemoveProject(projectName);
    }

    const submitRemoveProject = async (projectName) => {
        await axios.post(process.env.REACT_APP_API_ENDPOINT + '/secured/api/removeProject', {
            requestAddress: address,
            projectName: projectName
        })
            .then((response) => {
                toast.success("Successfully removed project")
                props.close()
            })
            .catch((error) => console.log(error));
    }

    return (
        <div>
            <Modal
                isOpen={props.show}
                onRequestClose={props.close}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className='modal-content'>
                    <form>
                        Remove project by name from database
                    </form>
                </div>
                <input
                    type="text"
                    name="projectName"
                    className="-input"
                    placeholder="project name"
                    onChange={handleRemoveProject}
                />

                <button
                    type="submit"
                    className='-button'
                    onClick={handleSubmit}
                >
                    Remove project
                </button>
            </Modal>
        </div>
    );
}
