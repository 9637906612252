import React, {useEffect, useState} from 'react';
import "../assets/scss/_sale-page.scss";
import "../assets/scss/_components.scss";
import "../assets/scss/_layout.scss"
import CheckCircle from "../assets/icons/sale/CheckCircle.svg"
import {Button, Divider, Input, Progress, Radio, Tabs} from 'antd';
import MetamaskProgress from "../components/modal2/MetamaskProgress";
import {Link, useLocation} from "react-router-dom";
import {
    claimTokens,
    closeCampaign,
    computeReleasableAmount,
    getChainlinkDataFeedLatestAnswer,
    getFundraisingInstance,
    getIndividualBalanceForToken,
    getIndividualBalances,
    getReleasedTokens,
    getVestingPlan,
    getWhitelistedTokens,
    invest,
    reclaimTokens
} from "../ethers/Fundraising";
import {approveAmount, getTokenDecimals, getTokenName, getTokenTicker, mintTestTokens} from "../ethers/ERC20Contract";
import {getSaleCampaignStatus, SaleCampaignStatus} from "../utils/SaleCampaignStatus";
import erc20InvestmentTokens from "../constants/ERC20InvestmentTokens";
import ProgressBar from "../components/sale/ProgressBar";
import Web3 from "web3";
import {toast} from "react-toastify";
import * as ethers from "ethers";
import LoadingModal from "../components/modals/LoadingModal";
import InvestmentTable from "../components/sale/InvestmentTable";
import {getFundraisingEntryByAddress, saveCustomizedSale} from "../controllers/FundraisingController";
import AddWhiteListedAddresses from "../components/modal2/AddWhiteListedAddresses";
import {useTimer} from "../hooks/useTimer";
import toSeconds from "../constants/timeConstants";
import SaleSettings from "../components/sale/SaleSettings";

const { TabPane } = Tabs

export default function Sale() {

    const location = useLocation();
    const saleUUID = new URLSearchParams(location.search).get("id");

    const timer = useTimer(new Date());
    const [timerTitle, setTimerTitle] = useState("")

    const provider = new ethers.BrowserProvider(window.ethereum);
    const [network, setNetwork] = useState('')

    const [isOwner, setIsOwner] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [editSale, setEditSale] = useState(false)

    const [investmentTokensArray, setInvestmentTokensArray] = useState([])

    const[saleReleaseContainer, setSaleReleaseContainer] = useState({})

    const[investmentToken, setInvestmentToken] = useState('')
    const[investmentAmount, setInvestmentAmount] = useState('')
    const[investmentTabe, setInvestmentTabe] = useState('')
    const[decimals, setDecimals] = useState('')

    const [tokenInformation, setTokenInformation] = useState({})
    const [timeline, setTimeline] = useState({})
    const [conditions, setConditions] = useState({})
    const [campaign, setCampaign] = useState({})
    const [hasCampaignData, setHasCampaignData] = useState(false)

    const [campaignStatus, setCampaignStatus] = useState('')
    const [successModal, setSuccessModal] = useState(false);
    const [campaignClosed, setCampaignClosed] = useState(false);
    const[rate, setRate] = useState()
    const[rateDelimiter, setRateDelimiter] = useState()

    const[projectName, setProjectName] = useState("")
    const[saleBlockchain, setSaleBlockchain] = useState("")

    const[whiteListedModal, setWhiteListedModal] = useState(false)
    useEffect(() => {
        if (!successModal){
            handleGetFundraisings()
        }
    },[successModal])

    useEffect(() => {
        if (campaignStatus !== SaleCampaignStatus.PRE_SALE){
            handleGetInvestmentTokens();
        }
    },[campaignStatus, successModal])

    useEffect(() => {
        handleSetNetwork()
        getFundraisingByAddress()
    }, [])

    const handleSetNetwork = async () => {
        setNetwork(await provider.getNetwork())
    }

    function reformatDate(dateStr) {
        const [datePart, timePart] = dateStr.split(', ');
        const [day, month, year] = datePart.split('/');
        return `${month}/${day}/${year}, ${timePart}`;
    }
    const handleGetFundraisings = async () => {
        const fundraisingInstance = await getFundraisingInstance(saleUUID);
        const vestingPlan = await getVestingPlan(saleUUID);

        const signer = await provider.getSigner();
        if (fundraisingInstance[0] === signer.address){
            setIsOwner(true)
        }

        setTokenInformation({
            tokenAddress: fundraisingInstance[11],
            tokenTicker: await getTokenTicker(fundraisingInstance[11]),
            tokenName: await getTokenName(fundraisingInstance[11]),
            network: "Sepolia"
        })

        const saleStart = handleTimestampToDate(fundraisingInstance[3].toString())
        const saleEnd = handleTimestampToDate(fundraisingInstance[4].toString())
        const saleStartDate = new Date(reformatDate(saleStart));
        const saleEndDate = new Date(reformatDate(saleEnd));
        const saleReclaim = new Date(saleEndDate.getTime() + toSeconds.day * 1000);
        const now = new Date();
        console.log(saleStartDate)
        console.log(saleEndDate)
        if (now < saleStartDate) {
            timer.setDeadline(saleStartDate);
            setTimerTitle("Time left until token sale starts");
        } else if (now >= saleStartDate && now <= saleEndDate) {
            timer.setDeadline(saleEndDate);
            setTimerTitle("Time left until token sale ends");
        } else if (now > saleEndDate && now <= saleReclaim) {
            timer.setDeadline(saleReclaim);
            setTimerTitle("Time left until reclaim phase is available");
        } else {
            setTimerTitle("Sale ends");
        }
        setTimeline({
            startDate: saleStart,
            endDate: saleEnd
        })

        setConditions({
            minimalInvestment: fundraisingInstance[8].toString(),
            cliffPeriod: vestingPlan[2].toString(),
            vestingPeriod: vestingPlan[3].toString(),
            releasePeriod: vestingPlan[4].toString(),
            tge: vestingPlan[5].toString(),
        })

        setCampaign({
            hardCap: fundraisingInstance[5].toString(),
            tokenAllocated: fundraisingInstance[1].toString(),
            saleType: fundraisingInstance[2] ? "public" : "private"
        })

        setCampaignStatus(getSaleCampaignStatus(fundraisingInstance[3].toString(),
            fundraisingInstance[4].toString(),
            fundraisingInstance[1].toString()))

        // Post-campaign data for claiming tokens
        const releasedTokens = await getReleasedTokens(saleUUID, signer.getAddress())
        const totalTokensAllocated = await getIndividualBalances(saleUUID)
        const claimableTokens = await computeReleasableAmount(saleUUID)

        // Calculate next release date
        let currentDateTimestamp = Math.floor(Date.now() / 1000);
        const vestingStart = vestingPlan[1]
        const cliffPeriod = vestingPlan[2]
        const vestingPeriod = vestingPlan[3]
        const releasePeriod = vestingPlan[4]
        const lastReleaseDate = vestingStart + cliffPeriod + vestingPeriod

        let releaseDate = vestingStart;

        if (currentDateTimestamp > lastReleaseDate){
            releaseDate = lastReleaseDate
        } else if(currentDateTimestamp > vestingStart + cliffPeriod){

            const vestingStartNormalized = Number(vestingStart)
            const releasePeriodNormalized = Number(releasePeriod)
            const currentDateInSeconds = Math.floor(Date.now() / 1000)

            const periodsPassed = Math.floor((currentDateInSeconds - vestingStartNormalized) / releasePeriodNormalized)
            releaseDate = vestingStartNormalized + ((periodsPassed + 1) * releasePeriodNormalized)
        }

        const releaseContainer = {
            "tokensReleased": Web3.utils.fromWei(releasedTokens.toString(), "ether"),
            "totalTokensAllocated": Web3.utils.fromWei(totalTokensAllocated.toString(), "ether"),
            "claimableTokens": Web3.utils.fromWei(claimableTokens.toString(), "ether"),
            "nextReleaseDate": handleTimestampToDate(releaseDate)
        }

        setCampaignClosed(fundraisingInstance[9])
        setRate(fundraisingInstance[6])
        setRateDelimiter(fundraisingInstance[7])

        setSaleReleaseContainer(releaseContainer)

        setHasCampaignData(true)
    }

    const getTestTokens = async (investmentTokenAddress) => {
        await mintTestTokens(investmentTokenAddress, "10000000000000000000000");
    }

    const handleChangeInvestmentToken = async (e) => {
        setInvestmentToken(erc20InvestmentTokens[network.chainId][e.target.value]);
        setDecimals(await getTokenDecimals(erc20InvestmentTokens[network.chainId][e.target.value]))
    }

    const SpecItem = ({title, value}) => {
      return (
          <div className="-spec-item">
              <div className="-text">{title}</div>
              <div className="-text dark">{value}</div>
          </div>
      )
    }

    const handleTimestampToDate = (timestamp) => {
        const date = new Date(Number(timestamp) * 1000);
        return date.toLocaleString();
    };

    const [step, setStep] = useState(0)

    const handleInvest = async () => {
        setSuccessModal(true)
        const calculatedApproveAmount = investmentAmount * Number(await getChainlinkDataFeedLatestAnswer(investmentToken)) * Number(rate) / Number(rateDelimiter) / Math.pow(10, Number(decimals));
        const approveTx = await approveAmount(investmentToken, erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, calculatedApproveAmount, decimals)
        if (approveTx !== undefined){
            const errorMsg = approveTx.toString().split('(')
            toast.error(errorMsg[0])
            setStep(0)
        } else {
            setStep(1)
            const tx = await invest(investmentAmount, investmentToken, saleUUID, decimals);
            if (tx !== undefined){
                const errorMsg = tx.toString().split('(')
                toast.error(errorMsg[0])
                setStep(0)
            } else {
                setStep(2)
                setSuccessModal(false)
            }
        }
    }

    const handleGetInvestmentTokens = async () => {
        const investmentTokens = await getWhitelistedTokens(saleUUID)
        const addressArray = investmentTokens.toString().split(',');
        let investTable = [];
        let tokenNameArray = [];
        for (const address of addressArray) {
            const investmentTokenAddress = address;
            const individualBalance = await getIndividualBalanceForToken(saleUUID, address)
            const balanceInEth = Web3.utils.fromWei(individualBalance.toString());
            let tokenName = await getTokenName(address);
            let tokenTicker = await getTokenTicker(address);

            investTable = [...investTable,
                {
                    investmentTokenAddress: investmentTokenAddress,
                    tokenName: tokenName,
                    tokenTicker: tokenTicker,
                    balanceEth: balanceInEth,
                    actionButton: campaignStatus === SaleCampaignStatus.FAILED
                },
            ]
            tokenNameArray.push(tokenTicker)
        }
        setInvestmentTabe(investTable)
        setInvestmentTokensArray(tokenNameArray)
        setInvestmentToken(erc20InvestmentTokens[network.chainId][tokenNameArray[0]]);
        setDecimals(await getTokenDecimals(erc20InvestmentTokens[network.chainId][tokenNameArray[0]]))
    }

    const handleReclaimTokens = async () => {
        setIsLoading(true);
        await reclaimTokens(saleUUID)
        setIsLoading(false);
    }

    const handleClaimTokens = async () => {
        setIsLoading(true);
        await claimTokens(saleUUID)
        setIsLoading(false);
    }

    const handleCloseCampaign = async () => {
        setIsLoading(true);
        await closeCampaign(saleUUID)
        setIsLoading(false);
    }


    const handleSaveCustomizedSale = async (projectName, network, saleIdx, header, keyBenefits, saleBackground, background) => {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const data = {
            ownerAddress: selectedAccount,
            projectName: projectName,
            address: saleIdx,

            updateData: header,
            keyBenefits: keyBenefits,
            saleBackground: saleBackground,
            background: background
        };
        try {
            const result = await saveCustomizedSale(data);
            console.log('Fundraising saved successfully:', result);
        } catch (error) {
            console.error('Error saving fundraising:', error);
        }
    };

    const getFundraisingByAddress = async () => {
        const fundraisingFromDb = await getFundraisingEntryByAddress(saleUUID);
        setProjectName(fundraisingFromDb.projectName);
        setSaleBlockchain(fundraisingFromDb.entry.network);
    }

    return (
        <>
            {/*<Success show={successModal} close={() => setSuccessModal(false)}/>*/}
            {/*<Loader show={successModal} close={() => setSuccessModal(false)}/>*/}
            <LoadingModal  show={isLoading} text='Handling event...' />
            <AddWhiteListedAddresses show={whiteListedModal} close={() => setWhiteListedModal(false)} />
            <MetamaskProgress selectedToken={investmentToken}
                              decimals={decimals}
                              show={successModal}
                              investmentAmount={investmentAmount}
                              saleUUID={saleUUID}
                              close={() => setSuccessModal(false)}
                              step={step}/>
            <div className="back home"></div>
            <div className="main-container">
                <div className="sale">
                    {editSale ?
                        <SaleSettings close={() => setEditSale(false)} saleId={saleUUID}/>
                        :
                    <>
                        <Link to={`/dfo-page?id=${projectName}`}>
                         <Button className="-to-project" type="primary">Go to Project</Button>
                        </Link>
                        <div>
                            <div className="-title offset centrate">
                                {projectName} Token Sale Page <Button onClick={() => setEditSale(true)}>Edit</Button>
                            </div>
                            <div className="-text">
                                $WWY is a utility token released in 2022 that is meant to power the WeWay ecosystem, WePad launchpad in particular. WeWay's vision is to establish a one-of-a-kind influencer metaverse where users can interact with their beloved celebrities through quests, contests, and NFTs. WeWay's fundraising journey includes three (3) IDOs hostedON ETHPad, BSCPad, AND VelasPad launchpads, one (1) IEO ON Gate.io Startup, AND one (1) private token sale. Collectively, these efforts have accumulated $2,720,000 in funding, highlighting WeWay's potential to revolutionize the entertainment industry.
                            </div>
                        </div>
                        <div className="-info">
                            <Tabs defaultActiveKey="1" type="card" horizontalMargin="0" verticalItemMargin="0">
                                <TabPane tab="Token Information" key="1">
                                    <div class="-tab">
                                        <SpecItem title="TOKEN ADDRESS" value={tokenInformation.tokenAddress} />
                                        <SpecItem title="TOKEN TICKER" value={tokenInformation.tokenTicker} />
                                        <SpecItem title="NETWORK" value={tokenInformation.network} />
                                    </div>
                                </TabPane>

                                <TabPane tab="Sale Timeline" key="2">
                                    <div class="-tab">
                                        <div className="-text grey">Start Date:</div>
                                        <div className="-text b black">{timeline.startDate}</div>
                                        <div className="-text grey">End Date:</div>
                                        <div className="-text b black">{timeline.endDate}</div>
                                    </div>
                                </TabPane>

                                <TabPane tab="Conditions" key="3">
                                    <div class="-tab">
                                        <SpecItem
                                            title="Minimal Investment"
                                            value={conditions.minimalInvestment ? Web3.utils.fromWei(conditions.minimalInvestment.toString(), "ether") : ""}
                                        />
                                        <SpecItem title="Cliff Period" value={conditions.cliffPeriod / 86400 + " days"} />
                                        <SpecItem title="Vesting Period" value={conditions.vestingPeriod / 86400 + " days"} />
                                        <SpecItem title="Release Period" value={conditions.releasePeriod / 86400 + " days"} />
                                    </div>
                                </TabPane>

                                <TabPane tab="Campaign Information" key="4">
                                    <div className="-tab">
                                        <SpecItem
                                            title="Hard Cap"
                                            value={campaign.hardCap ? Web3.utils.fromWei(campaign.hardCap.toString(), "ether") : ""}
                                        />
                                        <SpecItem
                                            title="Token Allocated"
                                            value={campaign.tokenAllocated ? Web3.utils.fromWei(campaign.tokenAllocated.toString(), "ether") : ""}
                                        />
                                        <SpecItem title="Sale type" value={campaign.saleType} />
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>
                        { timerTitle !== "Sale ends" && timerTitle !== "" &&
                            <div className="-progress" >
                                <div className="-chain">
                                    {saleBlockchain.toUpperCase()}
                                </div>
                                {hasCampaignData &&
                                    <>
                                        <div className="-title m">Sale Progress</div>
                                        <ProgressBar maxValue={Web3.utils.fromWei(campaign.hardCap.toString(), "ether")}
                                                     currentValue={Web3.utils.fromWei(campaign.tokenAllocated.toString(), "ether")}
                                                     intermediateValue={Web3.utils.fromWei(campaign.hardCap.toString(), "ether")}/>
                                    </>
                                 }
                                    <div>
                                        <div className="-title s">Time Left</div>
                                        <div className="-text">{timerTitle}</div>
                                    </div>
                                    <div className="-timer">
                                        <div className="-block">
                                            <div className="-text grey">Days</div>
                                            <div className="-title m">{timer.days}</div>
                                        </div>
                                        <Divider type="vertical" style={{height: '100%'}}/>
                                        <div className="-block">
                                            <div className="-text grey">Hours</div>
                                            <div className="-title m">{timer.hours}</div>
                                        </div>
                                        <Divider type="vertical" style={{height: '100%'}}/>
                                        <div className="-block">
                                            <div className="-text grey">Minutes</div>
                                            <div className="-title m">{timer.minutes}</div>
                                        </div>
                                        <Divider type="vertical" style={{height: '100%'}}/>
                                        <div className="-block">
                                            <div className="-text grey">Seconds</div>
                                            <div className="-title m">{timer.seconds}</div>
                                        </div>
                                    </div>
                            </div>
                        }
                        <Divider />
                        <div>
                            <div className="-title s">Campaign Information</div>
                            <div className="-text">Please review the information below to prepare for the sale'</div>
                        </div>

                        <div>
                        {/* TODO: when private*/}
                        <Button type="primary" onClick={() =>setWhiteListedModal(true)}>Whitelist participants</Button>
                        </div>

                        { (campaignStatus === SaleCampaignStatus.ACTIVE || campaignStatus === SaleCampaignStatus.PRE_SALE || (campaignStatus === SaleCampaignStatus.SUCCESS && !campaignClosed)) &&
                            <div className="-investment-block">
                                <div className={ (campaignStatus ===SaleCampaignStatus.ACTIVE || campaignStatus === SaleCampaignStatus.SUCCESS && !campaignClosed) ? "-select" : "-select blur"}>
                                    <div className="-title light" style={{ width: '100%' }}>{} now</div>
                                    <div>
                                        <div className="-text offset light">Investment Token</div>
                                        {investmentTokensArray.length > 0 && (
                                            <Radio.Group
                                                style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(80px, 1fr))' }}
                                                size="large"
                                                defaultValue={investmentTokensArray[0]}
                                                autoSize={true}
                                                onChange={handleChangeInvestmentToken}
                                            >
                                                {investmentTokensArray.map((token) => (
                                                    <Radio.Button key={token} value={token} style={{ textAlign: "center" }}>
                                                        {token}
                                                    </Radio.Button>
                                                ))}
                                            </Radio.Group>
                                        )}

                                    </div>
                                    <div>
                                        <div className="-text offset light">Quantity</div>
                                        <Input onChange={e => setInvestmentAmount(e.target.value)}/>
                                    </div>
                                    <Button type="primary" onClick={handleInvest}>Buy it now</Button>

                                    <div className="-text light">
                                        Introducing our powerful anti-aging facial cream that targets fine lines, wrinkles, and age spots, leaving your skin looking youthful and radiant.
                                    </div>
                                </div>
                                <div className="-benefits">
                                    {/* Hard code */}
                                    <div className="-title s">Key benefits</div>
                                    <div className="-item">
                                        <img src={CheckCircle} alt=""/>
                                        <div className="-text">Reduces the appearance of fine lines and wrinkles</div>
                                    </div>
                                    <div className="-item">
                                        <img src={CheckCircle} alt=""/>
                                        <div className="-text">Improves skin texture and tone</div>
                                    </div>
                                    <div className="-item">
                                        <img src={CheckCircle} alt=""/>
                                        <div className="-text">Improves skin texture and tone</div>
                                    </div>
                                    <div className="-item">
                                        <img src={CheckCircle} alt=""/>
                                        <div className="-text">Improves skin texture and tone</div>
                                    </div>
                                </div>
                            </div>
                        }
                        { investmentTabe.length > 0 &&
                            <InvestmentTable initialData={investmentTabe} handleReclaimTokens={handleReclaimTokens}/>
                        }
                        {campaignStatus === SaleCampaignStatus.SUCCESS &&
                            <div className="-claim-container">
                                <div className="-title">Tokens released</div>
                                <div className="-grid">
                                    <div className="-progress-wrapper">
                                        <Progress percent={saleReleaseContainer.tokensReleased / saleReleaseContainer.totalTokensAllocated * 100} showInfo={false}/>
                                    </div>
                                    <div className="-text">{saleReleaseContainer.tokensReleased} / {saleReleaseContainer.totalTokensAllocated}</div>
                                </div>
                                <div className="-grid">
                                    <div className="-text b">Claimable tokens</div>
                                    <div className="-text">{saleReleaseContainer.claimableTokens}</div>
                                </div>
                                <div className="-grid">
                                    <div className="-text b">Next release date</div>
                                    <div className="-text">{saleReleaseContainer.nextReleaseDate}</div>
                                </div>
                                <div className="-btn">
                                    <Button onClick={handleClaimTokens}>Claim Tokens</Button>
                                </div>
                            </div>
                        }
                        {campaignStatus === SaleCampaignStatus.FAILED &&
                            <div>
                            <div className="-title">
                                Token Reclaiming
                            </div>
                            <div className="-text">
                                Lorem ipsumThis information will be displayed publicly so be careful what you share.This information will be displayed publicly so be careful what you share.This information will be displayed publicly so be careful what you share.
                            </div>
                                <div className="-center offset">
                                    <Button type="primary">Reclaim Tokens</Button>
                                </div>
                        </div>

                        }

                        {campaignStatus === SaleCampaignStatus.SUCCESS && isOwner && !campaignClosed &&
                            <div className="-center offset">
                                <Button type="primary" onClick={handleCloseCampaign}>Close campaign</Button>
                            </div>
                        }
                    </>
                }



                </div>
            </div>

            <button onClick={() =>getTestTokens (erc20InvestmentTokens[network.chainId].USDT)}>Get USDT tokens</button>
            <button onClick={() =>getTestTokens (erc20InvestmentTokens[network.chainId].USDC)}>Get USDC tokens</button>
            <button onClick={() =>getTestTokens (erc20InvestmentTokens[network.chainId].DAI)}>Get DAI tokens</button>
        </>
    )
}
