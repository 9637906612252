import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import "../../assets/scss/_modal.scss"
import axios from "axios";
import {toast} from "react-toastify";


const customStyles = {
    content: {
        maxWidth: 500,
        width: '35%',
        maxHeight: '60vh',
        overflowY: 'auto',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '4px',
        marginRight: '-50%',
        border: '1px solid #000',
        transform: 'translate(-50%, -50%)',
    },
};

export default function MatchMassageModal(props) {

    const[messageText, setMessageText] = useState("");

    const handleChangeMessage = (event) => {
        setMessageText(event.target.value);
    }

    const handleSendMessage = async() => {
        await axios.post(process.env.REACT_APP_API_ENDPOINT + '/secured/api/sendTelegramNotification', {
            messageText: messageText,
            receiptAddress: props.curatorWallet
        })
            .then( () => {
                toast.success("Invitation successfully sent to " + props.currentMatchCurator)
                props.close()
            });
    }

    return (
        <div>
            <Modal
                isOpen={props.show}
                // onAfterOpen={afterOpenModal}
                onRequestClose={props.close}
                style={customStyles}
                contentLabel="Curator Matching Modal"
            >
                <div className='modal-content small-margin'>
                    <div className="-text">Send an invitation to {props.currentMatchCurator}</div>
                    <textarea
                        type="text"
                        className='-textarea xl offset'
                        name='messageText'
                        maxLength="250"
                        onChange={handleChangeMessage}
                        value={messageText}
                    />
                    <button className="-button" onClick={handleSendMessage}>Send</button>
                </div>
            </Modal>
        </div>
    );
}
